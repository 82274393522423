import React, { useState } from 'react'
import Dashboard from '../Dashboard'
import { useSelector } from 'react-redux'
import { createProduct } from '../../Actions/Product'

// Componenent
import ContinueSellOutStockCheckbox from '../../Components/AddProduct/ContinueSellOutStockCheckbox'
import IsPhysicalCheckbox from '../../Components/AddProduct/IsPhysicalCheckbox'
import CostInput from '../../Components/AddProduct/CostInput'
import DescriptionInput from '../../Components/AddProduct/DescriptionInput'
import DiscountInput from '../../Components/AddProduct/DiscountInput'
import ImageInput from '../../Components/AddProduct/ImageInput'
import PriceInput from '../../Components/AddProduct/PriceInput'
import TagsInput from '../../Components/AddProduct/TagsInput'
import TitleInput from '../../Components/AddProduct/TitleInput'
import TypeInput from '../../Components/AddProduct/TypeInput'
import VendorInput from '../../Components/AddProduct/VendorInput'
import PublicNowToggle from '../../Components/AddProduct/PublicNowToggle'
import QuantityInput from '../../Components/AddProduct/QuantityInput'
import SKUInput from '../../Components/AddProduct/SKUInput'
import TrackQuantityCheckbox from '../../Components/AddProduct/TrackQuantityCheckbox'
import CategorySelect from '../../Components/AddProduct/CategorySelect'
import Toast from '../../Components/Toast'

function AddProduct({ history }) {

    const { auth } = useSelector(state => ({ ...state }))
    const { token } = auth

    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        title: '',
        description: '',
        status: true, // default public [private]
        mainCategory: '',
        subCategory: {
            name: '',
            id: ''
        },
        brand: {
            name: '',
            id: ''
        },
        organization: {
            productType: '', // powerbank, battery // it ID
            vendor: '', // like brand // it ID
            tags: []
        },
        featureImage: '',
        media: [],
        pricing: {
            price: 0,
            discount: 0, // default = null
            costPrice: '' // default = null
        },
        inventory: {
            sku: '',
            isPhysicalProduct: true,
            trackQty: true,
            sellOnOutStock: true, // if track qty is true only. else false
            quantity: 1  // if track qty is true only. else set it null
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log('clcic');

        setLoading(true)
        let productData = new FormData()

        for (var key in state) {
            if (!['media', 'organization', 'pricing', 'inventory'].includes(key)) productData.append(key, state[key])
        }

        // Media
        state.media.map((f, i) => productData.append(`media${i}`, f))

        // Oraganization
        productData.append('productType', state.organization.productType)
        productData.append('vendor', state.organization.vendor)
        productData.append('tags', state.organization.tags)

        // Pricing
        productData.append('price', state.pricing.price)
        productData.append('discount', state.pricing.discount)
        productData.append('costPrice', state.pricing.costPrice)

        // Inventory
        productData.append('sku', state.inventory.sku)
        productData.append('isPhysicalProduct', state.inventory.isPhysicalProduct)
        productData.append('trackQty', state.inventory.trackQty)
        productData.append('sellOnOutStock', state.inventory.sellOnOutStock)
        productData.append('quantity', state.inventory.quantity)

        // console.log([...productData])
        try {
            let res = await createProduct(token, productData)
            // console.log('Product CREATE RES ===> ', res.data.data._id)

            // res && res.data && res.data.success && window.location.href = `http://localhost:8000/api/product/image/${res.data.data._id}`
            res && res.data && res.data.success && setTimeout( () => {
                window.location.reload()
            }, 1000)

        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    const inputData = { state, setState }

    return (
        <Dashboard url={history.location.pathname} auth={auth}>
        <section className="col-lg-8 pt-lg-4 pb-4 mb-3 addProduct">
            <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
                <form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data" htmlescape="true" noValidate>
                <div className="d-sm-flex flex-wrap justify-content-between align-items-center pb-2">
                    <h2 className="h3 py-2 me-2 text-center text-sm-start">Add New Product</h2>
                    <CategorySelect {...inputData} />
                </div>
                    <TitleInput {...inputData} />
                    <DescriptionInput {...inputData} />
                    <div className="row">
                        <PriceInput {...inputData} />
                        <DiscountInput {...inputData} />
                        <CostInput {...inputData} />
                    </div>
                    <ImageInput {...inputData} />
                    <div className="row">
                        <TypeInput {...inputData} />
                        <VendorInput {...inputData} />
                        <TagsInput {...inputData} />
                    </div>

                    <div className="row">
                        <SKUInput {...inputData} />
                        {
                            state.inventory.trackQty &&
                            <QuantityInput {...inputData} />
                        }
                    </div>
                    <div className="row">
                        <div className="col-sm-6 mb-0 pt-3">
                            <IsPhysicalCheckbox {...inputData} />
                            <TrackQuantityCheckbox {...inputData} />
                        </div>
                        <div className="col-sm-6 mb-0 pt-3">
                            {
                                state.inventory.trackQty &&
                                <ContinueSellOutStockCheckbox {...inputData} />
                            }
                        </div>
                        <div className='col-md-12'>
                            <PublicNowToggle {...inputData} />
                        </div>
                    </div>
                    <button onClick={e => handleSubmit} disabled={loading} className="btn btn-primary d-block w-100 mt-4">
                        { loading
                            ? <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</>
                            : <><i className="ci-cloud-upload fs-lg me-2" />Upload Product</>
                        }
                    </button>
                </form>
            </div>
        </section>
        </Dashboard>
    )
}

export default AddProduct
