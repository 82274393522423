import React, { useState, useEffect } from 'react'
import { createPortal } from "react-dom";

function Toast({ message, setShowToast }) {

    const [show, setShow] = useState(true)

    useEffect(() => {
        setShow(newToast)
        setTimeout(() => {
            setShow(<></>)
            setShowToast(false)
        }, 3000);
    }, [])

    const newToast = <div className={`show text-danger toast align-items-center fade position-fixed bottom-0 end-0 p-3 me-3 mb-3`} role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex">
                            <div className="toast-body fw-bold">
                                <i className="ci-close-circle me-2 fs-lg"></i>
                                <span className='fs-lg'>{message}</span>
                            </div>
                            {/* <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button> */}
                            <button onClick={ () => setShowToast(false) } type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                    </div>

    // return (
    //     <>
    //         <div className={`${show && 'show'} text-danger toast align-items-center fade position-fixed bottom-0 end-0 p-3 me-3 mb-3`} role="alert" aria-live="assertive" aria-atomic="true">
    //             <div className="d-flex">
    //                 <div className="toast-body fw-bold">
    //                     <i className="ci-close-circle me-2 fs-lg"></i>
    //                     <span className='fs-lg'>Please login to continue!</span>
    //                 </div>
    //                 <button onClick={ () => setShow(false) } type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
    //             </div>
    //         </div>
    //     </>
    // )

    return createPortal(
        <>{show}</>,
        document.getElementById("modal_root"),
    );
}
  
export default Toast


