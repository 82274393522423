import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Popular from './Category/Popular'
import BrandHeader from './Category/BrandHeader'

function Category() {

    const dispatch = useDispatch()
    const state = useSelector( state => ({...state}) )
    const [category, setCategory] = useState([])
    const [showTopCategory, setShowTopCategory] = useState(true)


    useEffect(() => {
        if(state && state.category) {
            // console.log('load category from state');
            setCategory([...state.category])
            return
        }
        loadCategory()
    }, [])

    const loadCategory = async () => {

        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API}/main-categories`)
            // data.map((d, i) => i === 1 ? d.active = true : d.active = false)
            data.map((d, i) => d.active = false)
            // setCategory([...data, { name: 'Popular Categories', icon: 'ci-heart', active: true, group: [''] }])
            setCategory([...data])
            dispatch({
                type: 'SET',
                payload: [...data]
            })
            // console.log('data category fro in', [...data]);
            
        } catch (error) {
            console.log('Error on connet to api/main-category');
        }
    }

    const topCategory = [
        // { name: 'Electronics', icon: '3-1_thumb.jpg'},
        { name: 'Smartphone & Tablet', icon: '6-0_thumb.jpg'},
        { name: 'Laptop & Desktop', icon: '8-0_thumb.jpg'},
        // { name: 'Smartwatch', icon: '24-0_thumb.jpg'},
        { name: 'Camera & Recorder', icon: '25-0_thumb.jpg'},
        { name: 'Audio', icon: '26-0_thumb.jpg'},
        { name: 'Watch', icon: '27-0_thumb.jpg'},
        { name: 'Drone', icon: '35-0_thumb.jpg'},
        { name: 'Television', icon: '36-0_thumb.jpg'},
        { name: 'Gaming', icon: '37-0_thumb.jpg'},
        // { name: 'Kid Toy', icon: '9-0_thumb.jpg'},
        // { name: 'Pet Shop', icon: '22-0_thumb.jpg'},
        { name: 'Sale', icon: '23-0_thumb.jpg'},
        // { name: 'Kids Clothing', icon: '28-0_thumb.jpg'},
        // { name: 'Mens Clothing', icon: '29-0_thumb.jpg'},
        // { name: 'Womens Clothing', icon: '30-0_thumb.jpg'},
        // { name: 'Motorcycle', icon: '32-0_thumb.jpg'},
        // { name: 'Cosmetics', icon: '31-0_thumb.jpg'},
        // { name: 'Jewelry', icon: '33-0_thumb.jpg'},
        // { name: 'Food & Drink', icon: '34-0_thumb.jpg'},
    ]

    const handleClickCategory = (index) => {
        // console.log(index);
        if (index === 168) {
            setShowTopCategory(true)
            return
        }
        setShowTopCategory(false)
        let tempCat = [...category]
        tempCat.map((f, i) => i === index ? f.active = true : f.active = false)
        setCategory(tempCat)
        // console.log(category);
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-3 pe-0'>
                    <div className="simplebar-content-wrapper bg-light borderx xrounded-3 rounded-start border-start border-top border-bottom" style={{ height: '100%', overflow: 'hidden scroll' }} role="tablist">
                        <div className="simplebar-content" styles={{ padding: '160px 0px 0px' }}>
                            <div className="tab-contentz">

                                {/* sidebar-nav tab-pane fade show active  */}
                                <div className="pb-3 pt-3" id="categories">
                                    <div className="widget widget-categories">
                                        <div className="accordion list-group" id="shop-categories">

                                            <div className={`accordion-item border-bottom ${ showTopCategory && 'bg-faded-accent'}`}>
                                                <h3 className="accordion-header px-grid-gutterF px-4" role="tabpanel">
                                                    <a href={`#`} onMouseOver={() => handleClickCategory(168)} className='nav-link-style d-block fs-md fw-bold py-3 text-dark'
                                                    hrefuck={`#list-default`} id={`list-default-list`} data-bs-toggle="list" role="tab" aria-controls={`list-default`}>
                                                        <span className="d-flex align-items-center">
                                                            <i className='ci-heart opacity-60 fs-lg me-2'></i>
                                                            Popular Categories
                                                        </span>
                                                    </a>
                                                </h3>
                                            </div>
                                            {
                                                category.map((f, i) => (
                                                    <div className={`accordion-item ${i + 1 !== category.length && 'border-bottom'} ${f.active && 'bg-faded-accent'}`} key={i}>
                                                        <h3 className="accordion-header px-grid-gutterF px-4" role="tabpanel">
                                                            <a href={`/dashboard-category/${f._id}`} onMouseOver={() => handleClickCategory(i)} className={`nav-link-style d-block fs-md fw-bold py-3 ${f.active && 'active'} text-dark`} hrefuck={`#list-${i}`} id={`list-${i}-list`} data-bs-toggle="list" role="tab" aria-controls={`list-${i}`}>
                                                                <span className="d-flex align-items-center">
                                                                    <i className={`${f.icon} opacity-60 fs-lg me-2`}></i>
                                                                    {f.name}
                                                                </span>
                                                            </a>
                                                        </h3>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 mx-0 ps-0'>
                    <div className='bg-light xrounded-3 border roundedx h-100 rounded-end'>
                        <div className="tab-content pt-3 px-3">
                                            {
                                                console.log(category)
                                            }
                            { showTopCategory ? 
                                <div className={`${ showTopCategory && 'active'} tab-pane`} id='list-default' role="tabpanel" aria-labelledby={`list-default-list`}>
                                    <div className='row g-0 ps-1 pt-30 pb-4'>
                                    {
                                        topCategory.map( (cat, i) => (
                                            <div className="col-sm-4 px-2 mb-grid-gutterx py-3" key={i}>
                                                <a className="d-block text-center text-decoration-none me-1" href="#">
                                                    <div className='bg-secondary d-flex justify-content-center mx-auto my-auto' style={{
                                                            height: '70px',
                                                            width: '70px',
                                                            borderRadius: '100%',
                                                            display: 'inline-block'
                                                        }}>
                                                        <img className="d-block mx-auto my-auto p-3 mb-1"
                                                        src={ require(`../Assets/icon/${cat.icon}`).default }
                                                        alt={cat.name} />
                                                    </div>
                                                    <h3 className="fs-sm pt-1 mb-0 text-center">{cat.name}</h3>
                                                </a>
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                            :
                                category.map((f, i) => (
                                    <div id={`list-${i}`} className={`tab-pane ${f.active && 'active'}`} role="tabpanel" aria-labelledby={`list-${i}-list`} key={i}>
                                        {
                                            <Popular group={f.group} mainId={f._id} />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='col-md-5 mx-0 ps-2'>
                    <BrandHeader />
                </div>

            </div>
        </>
    )
}

export default Category
