import React from 'react'

function PublicNowToggle({ state, setState }) {
    return (
        <div className="py-2">
            <div className="mb-0 pb-2 form-check form-switch">
                <input
                    onChange={(e) => setState({ ...state, status: e.target.checked })}
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitch2"
                    checked={state.status}
                    // defaultChecked={state.status}
                />
                <label className="form-check-label fw-bold" htmlFor="customSwitch2">Public now</label>
            </div>
        </div>
    )
}

export default PublicNowToggle
