import React from 'react'

function IsPhysicalCheckbox({ state, setState }) {
    return (
        <div className="form-check">
            <input
                onChange={(e) => setState({ ...state, inventory: { ...state.inventory, isPhysicalProduct: e.target.checked } })}
                defaultChecked={state.inventory.isPhysicalProduct}
                className="form-check-input" type="checkbox" id="ex-check-2"
            />
            <label className="form-check-label" htmlFor="ex-check-2">This is a physical product</label>
        </div>
    )
}

export default IsPhysicalCheckbox
