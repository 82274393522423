import React from 'react'

function SKUInput({ state, setState }) {
    return (
        <div className="col-sm-6">
            <div className="mb-1">
                <label className="form-label fw-bold" htmlFor="unp-product-name">SKU</label>
                <input
                    value={state.inventory.sku}
                    onChange={(e) => setState({ ...state, inventory: { ...state.inventory, sku: e.target.value } })}
                    className="form-control" placeholder='WAL_BLK_25' type="text" id="unp-product-name"
                />
            </div>
        </div>
    )
}

export default SKUInput
