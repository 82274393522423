import React from 'react'

function QuantityInput({ state, setState }) {
    return (
        <div className="col-sm-6">
            <div className="mb-1">
                <label className="form-label fw-bold" htmlFor="unp-product-name">Quantity</label>
                <input
                    value={state.inventory.quantity}
                    onChange={(e) => setState({ ...state, inventory: { ...state.inventory, quantity: e.target.value } })}
                    className="form-control" placeholder='10' type="number" id="unp-product-name" autoComplete="off"
                />
            </div>
        </div>
    )
}

export default QuantityInput
