import React from 'react'

function Footer() {
    return (
        <>
            {/* Light footer */}
            <footer className="bg-secondary pt-5">
                {/* <div className="container">
                    <div className="row pb-2">
                        <div className="col-md-4 col-sm-6">
                            <div className="widget widget-links pb-2 mb-4">
                                <h3 className="widget-title">Shop departments</h3>
                                <ul className="widget-list">
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Sneakers &amp; Athletic</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Athletic Apparel</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Sandals</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Jeans</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Shirts &amp; Tops</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Shorts</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">T-Shirts</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Swimwear</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Clogs &amp; Mules</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Bags &amp; Wallets</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Accessories</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Sunglasses &amp; Eyewear</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Watches</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="widget widget-links pb-2 mb-4">
                                <h3 className="widget-title">Account &amp; shipping info</h3>
                                <ul className="widget-list">
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Your account</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Shipping rates &amp; policies</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Refunds &amp; replacements</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Order tracking</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Delivery info</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Taxes &amp; fees</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget widget-links pb-2 mb-4">
                                <h3 className="widget-title">About us</h3>
                                <ul className="widget-list">
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">About company</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Our team</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">Careers</a>
                                    </li>
                                    <li className="widget-list-item">
                                        <a href="#" className="widget-list-link">News</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="widget pb-2 mb-4">
                                <h3 className="widget-title pb-1">Stay informed</h3>
                                <form className="input-group flex-nowrap">
                                    <i className="ci-mail position-absolute top-50 translate-middle-y text-muted fs-base ms-3" />
                                    <input className="form-control rounded-start" type="email" placeholder="Your email" required />
                                    <button className="btn btn-primary" type="submit">Subscribe*</button>
                                </form>
                                <div className="form-text">*Subscribe to our newsletter to receive early discount offers, updates and new products info.</div>
                            </div>
                            <div className="widget pb-2 mb-4">
                                <h3 className="widget-title pb-1">Download our app</h3>
                                <div className="d-flex flex-wrap">
                                    <div className="me-2 mb-2">
                                        <a className="btn-market btn-apple" href="#" role="button">
                                            <span className="btn-market-subtitle">Download on the</span>
                                            <span className="btn-market-title">App Store</span>
                                        </a>
                                    </div>
                                    <div className="mb-2">
                                        <a className="btn-market btn-google" href="#" role="button">
                                            <span className="btn-market-subtitle">Download on the</span>
                                            <span className="btn-market-title">Google Play</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="pt-5" style={{ backgroundColor: '#ecf2f7' }}>
                    <div className="container">
                        <div className="row pb-3">
                            <div className="col-md-3 col-sm-6 mb-4">
                                <div className="d-flex">
                                    <i className="ci-rocket text-primary" style={{ fontSize: '2.25rem' }} />
                                    <div className="ps-3">
                                        <h6 className="fs-base mb-1">Fast and Easy delivery</h6>
                                        <p className="mb-0 fs-ms text-muted">Free delivery for all orders over $200</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-4">
                                <div className="d-flex">
                                    <i className="ci-currency-exchange text-primary" style={{ fontSize: '2.25rem' }} />
                                    <div className="ps-3">
                                        <h6 className="fs-base mb-1">Money back guarantee</h6>
                                        <p className="mb-0 fs-ms text-muted">We return money within 30 days</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-4">
                                <div className="d-flex">
                                    <i className="ci-support text-primary" style={{ fontSize: '2.25rem' }} />
                                    <div className="ps-3">
                                        <h6 className="fs-base mb-1">24/7 customer support</h6>
                                        <p className="mb-0 fs-ms text-muted">Friendly 24/7 customer support</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 mb-4">
                                <div className="d-flex">
                                    <i className="ci-card text-primary" style={{ fontSize: '2.25rem' }} />
                                    <div className="ps-3">
                                        <h6 className="fs-base mb-1">Secure online payment</h6>
                                        <p className="mb-0 fs-ms text-muted">We possess SSL / Secure сertificate</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mb-5" />
                        <div className="row pb-2">
                            <div className="col-md-6 text-center text-md-start mb-4">
                                <div className="text-nowrap mb-4">
                                    <a className="d-inline-block align-middle mt-n1 me-3" href="#">
                                        <img className="d-block" width={200} src={ require('../Assets/logo.svg').default } alt="Daklouk" />
                                    </a>
                                    {/* <div className="btn-group dropdown disable-autohide">
                                        <button className="btn btn-outline-secondary btn-sm dropdown-toggle px-2" type="button" data-bs-toggle="dropdown">
                                            <img className="me-2" width={20} src="path-to-image" alt="English" />
                Eng / $
              </button>
                                        <ul className="dropdown-menu my-1">
                                            <li className="dropdown-item">
                                                <select className="form-select form-select-sm">
                                                    <option value="usd">$ USD</option>
                                                    <option value="eur">€ EUR</option>
                                                    <option value="ukp">£ UKP</option>
                                                    <option value="jpy">¥ JPY</option>
                                                </select>
                                            </li>
                                            <li>
                                                <a className="dropdown-item pb-1" href="#">
                                                    <img className="me-2" width={20} src="path-to-image" alt="Français" />
                    Français
                  </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item pb-1" href="#">
                                                    <img className="me-2" width={20} src="path-to-image" alt="Deutsch" />
                    Deutsch
                  </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <img className="me-2" width={20} src="path-to-image" alt="Italiano" />
                    Italiano
                  </a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className="widget widget-links">
                                    <ul className="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">
                                        <li className="widget-list-item me-4">
                                            <a href="/" className="widget-list-link">Home</a>
                                        </li>
                                        <li className="widget-list-item me-4">
                                            <a href="#" className="widget-list-link">Feedback</a>
                                        </li>
                                        <li className="widget-list-item me-4">
                                            <a href="#" className="widget-list-link">Privacy</a>
                                        </li>
                                        <li className="widget-list-itemme-4">
                                            <a href="#" className="widget-list-link">Terms of use</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 text-center text-md-end mb-4">
                                <div className="mb-3">
                                    {/* <a className="btn-social bs-outline bs-twitter ms-2 mb-2" href="#"><i className="ci-twitter" /></a> */}
                                    <a className="btn-social bs-outline bs-facebook ms-2 mb-2" href="https://web.facebook.com/Daklouk-101208195438669" target='_blank'><i className="ci-facebook" /></a>
                                    <a className="btn-social bs-outline bs-messenger ms-2 mb-2"><i className="ci-messenger"></i></a>
                                    <a className="btn-social bs-outline bs-google ms-2 mb-2"><i className="ci-google"></i></a>
                                    <a className="btn-social bs-outline bs-telegram ms-2 mb-2"><i className="ci-telegram"></i></a>
                                    {/* <a className="btn-social bs-outline bs-youtube ms-2 mb-2" href="#"><i className="ci-youtube" /></a> */}
                                </div>
                                <img className="d-inline-block" width={187} src={ require('../Assets/payment.png').default } alt="Payment methods" />
                            </div>
                        </div>
                        <div className="pb-4 fs-xs text-muted text-center text-md-start">© All rights reserved. Made by <a className="text-muted" href="/">daklouk.com</a></div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer
