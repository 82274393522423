import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { numberValidate, limitLength} from '../Hooks/FormValidation'
import { useHistory } from 'react-router-dom'
import { startVerify } from '../Actions/Auth'
import Modal from './Modal'

function PhoneVerifyModal({ data, showToggle, show }) {

    const history = useHistory()
    const dispatch = useDispatch()

    const [verifyCode, setVerifyCode] = useState('')
    const [count, setCount] = useState(99)

    const [err, setErr] = useState('')
    const [errCount, setErrCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [block, setBlock] = useState(false)

    const errMarkUp = <div className="invalid-feedback d-block text-left">{err}</div>

    // Countdown expire code
    useEffect(() => {
        const timer = setTimeout(() => {
            if(count === 0) {
                return () => clearTimeout(timer)
            }
            setCount(count - 1)
        }, 1000)
        return () => clearTimeout(timer)
      })

    // Pass code and data to Server
    const handleSubmitVerify = async (e) => {
        e.preventDefault()
        setErr('')
        setLoading(true)
        let res = await startVerify(data, verifyCode)
        console.log(res)
        if (res.data.success === true) {
            // console.log('SAVE USER RES IN REDUX & LOCAL STORAGE THEN REDIRECT ==>')
                // console.log(res.data)

                // Save res.data to Local Storage
                window.localStorage.setItem('auth', JSON.stringify({ token: res.data.token, user: res.data.user }))

                // Save res.data to redux
                dispatch({
                    type: 'LOGGED_IN',
                    payload: { token: res.data.token, user: res.data.user }
                })

                // When success redirect to Homepage
                return history.push('/')
        } else if ( res.data.attempt === true ) {
            setLoading(false)
            if ( errCount < 5) {
                setErrCount(errCount+1)
                return setErr(`Wrong Code (${errCount+1} ${(errCount<1)?'time':'times'}). Try again!`)
            } else {
                setCount(0)
                setErr(<span>Many attempt using a phone number that cannot be verified or wrong code! <br/>You must wait 48 hours before trying again.</span>)
                return setErrCount(errCount+1)
            }
        } else if ( res.data.block ) {
            setLoading(false)
            setBlock(true)
            return setErr(res.data.message)
        } else if (res.data.error && res.data.error.code === 60202) {
            setBlock(true)
            return setErr('You have attempted to check a verification more than 5 times and have reached the limit.')
        } else {
            setLoading(false)
            return setErr('Could\'t verify! please try again.')
        }
           
    }

    const onChangeCode = (e) => {
        limitLength(e, 6)
        setVerifyCode(e.target.value)
    }

    return (
        <Modal showToggle={showToggle} show={show}>
            <div className="alert alert-light py-0 px-lg-3 text-center">
                <i className="h1 ci-mobile text-accent mb-4 mt-4" />
                <h1 className="h4 text-accent">Verify Your Number</h1>
                <p className="fs-sm pb-2 text-muted">Please enter Code sent <br />to <strong className='text-success'>{data.phone}</strong></p>

                <div className="mb-4 mt-4 ">
                    <input onChange={ onChangeCode } onKeyDown={ numberValidate } className="form-control" type="number" id="number-input" placeholder='315875' />
                    { errMarkUp }
                </div>

                <button onClick={ handleSubmitVerify } disabled={ (count === 0) || (errCount > 5) || block } className="btn btn-accent btn-block w-100 mb-2">
                { loading 
                    ? <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</>
                    : <>{`Verify (${count}s)`}</>
                }
                </button>
                { errCount <= 5 &&<a className={`fs-sm mt-5 ${ (count === 0) ? 'text-danger' : 'text-muted' }`} >Resend Code</a> }
            </div>
        </Modal>
    )
}

export default PhoneVerifyModal
