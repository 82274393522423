import React from 'react'

function TitleInput({ state, setState }) {
    return (
        <div className="mb-3 pb-0">
            <label className="form-label fw-bold" htmlFor="unp-product-name">Product title</label>
            <input
                onChange={(e) => setState({ ...state, title: e.target.value })}
                value={state.title}
                className="form-control normal-input"
                type="text" id="unp-product-name"
                placeholder='Short sleeve t-shirt'
                required
            />
            <div className="form-text">Maximum 100 characters. No HTML or emoji allowed.</div>
        </div>
    )
}

export default TitleInput
