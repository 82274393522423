import React, { useState, useEffect } from 'react'
import ProductCard from '../Components/ProductCard'
import { allProducts } from '../Actions/Product'
import Category from '../Components/Category'
import Pagination from '../Components/Pagination'

import { useSelector, useDispatch } from 'react-redux'
import { getWishList } from '../Actions/WishList'

function Shop() {

    const [products, setProducts] = useState(null)
    const [wishListId, setWishListId] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const { auth, wishlist } = useSelector(state => ({...state}))
    const dispatch = useDispatch()

    useEffect(() => {
        !products && loadProduct()
        auth?.token && !wishlist && getWishList(auth?.token).then( r => {
            if(r.data?.success) {
                // dispatch({
                //     type: 'SET_WISHLIST',
                //     payload: [...r.data.data]
                // })
                let temp = r.data.data.map( w => w.productId._id )
                setWishListId(temp)
            }
        })    
    }, [])

    const loadProduct = async () => {
        try {
            let { data } = await allProducts()
            setProducts(data)
            setLoaded(true)
            // console.log(data);
        } catch (error) {
            console.log('Error: connecting to api/products');
        }
    }

    return (
        <>
            <div className='container'>
            <div className="bg-light rounded-3 py-30 my-3 border">
            <div className="row align-items-center">
                <div className="col-md-9">
                <div className="px-4 pe-sm-0 ps-sm-5"><span className="badge bg-danger">Limited Offer</span>
                    <h2 className="mb-1">All new Last Gen iPad Pro</h2>
                    <p className="h5 text-body fw-light">at discounted price. Hurry up!</p>
                    <a className="btn btn-accent" href="#">View offers<i className="ci-arrow-right fs-ms ms-1" /></a>
                </div>
                </div>
                <div className="col-md-3"><img src="https://www.touchpoint.com.au/wp-content/uploads/2020/12/apple_new-ipad-air_new-design_09152020.jpg.news_app_ed.jpg" alt="iPad Pro Offer" /></div>
            </div>
            </div>
            </div>


            <div className="page-title-overlap pt-40">
                <div className="containerc d-lg-flex justify-content-between py-2 py-lg-3 text-accent">
                    <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-lightx flex-lg-nowrap justify-content-center justify-content-lg-start">
                                <li className="breadcrumb-item"><a className="text-nowrap" href="/"><i className="ci-home" />Home</a></li>
                                <li className="breadcrumb-item text-nowrap"><a href="#">Shop</a>
                                </li>
                                <li className="breadcrumb-item text-nowrap active" aria-current="page">Phone</li>
                            </ol>
                        </nav>
                    </div>
                    {/* <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                        <h1 className="h3 mb-0">Shop grid left sidebar</h1>
                    </div> */}
                </div>
            </div>

            <div className="container pb-5 mb-2 mb-md-4">
                <div className="row">
                    {/* Sidebar*/}
                    <aside className="col-lg-4">
                        {/* Sidebar*/}
                        <div className="offcanvas offcanvas-collapse bg-white w-100 rounded-3 shadow-lg0 border py-1" id="shop-sidebar" style={{ maxWidth: '22rem' }}>
                            <div className="offcanvas-cap align-items-center shadow-sm">
                                <h2 className="h5 mb-0">Filters</h2>
                                <button className="btn-close ms-auto" type="button" data-bs-dismiss="offcanvas" aria-label="Close" />
                            </div>
                            <div className="offcanvas-body py-grid-gutter px-lg-grid-gutter">
                                {/* Categories*/}
                                <div className="widget widget-categories mb-4 pb-4 border-bottom">
                                    <h3 className="widget-title fw-bold">Categories</h3>
                                    <div className="accordion mt-n1" id="shop-categories">
                                        

                                        {/* Accessories*/}
                                        <div className="accordion-item">
                                            <h3 className="accordion-header"><a className="accordion-button collapsed" href="#accessories" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accessories">Accessories</a></h3>
                                            <div className="accordion-collapse collapse" id="accessories" data-bs-parent="#shop-categories">
                                                <div className="accordion-body">
                                                    <div className="widget widget-links">
                                                        <ul className="widget-list">
                                                            <li className="widget-list-item"><a className="widget-list-link d-flex justify-content-between align-items-center" href="#"><span>View all</span><span className="fs-xs text-muted ms-3">920</span></a></li>
                                                            <li className="widget-list-item"><a className="widget-list-link d-flex justify-content-between align-items-center" href="#"><span>Belts</span><span className="fs-xs text-muted ms-3">364</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                {/* Price range*/}
                                <div className="widget mb-4 pb-4 border-bottom">
                                    <h3 className="widget-title fw-bold">Price</h3>
                                    <div className="range-slider" data-start-min={250} data-start-max={680} data-min={0} data-max={1000} data-step={1}>
                                        <div className="d-flex pb-1">
                                            <div className="w-50 pe-2 me-2">
                                                <div className="input-group input-group-sm"><span className="input-group-text">$</span>
                                                    <input className="form-control range-slider-value-min" type="text" />
                                                </div>
                                            </div>
                                            <div className="w-50 ps-2">
                                                <div className="input-group input-group-sm"><span className="input-group-text">$</span>
                                                    <input className="form-control range-slider-value-max" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Filter by Brand*/}
                                <div className="widget widget-filter mb-4 pb-4 border-bottom">
                                    <h3 className="widget-title fw-bold">Brand</h3>
                                    <div className="input-group input-group-sm mb-2">
                                        <input className="widget-filter-search form-control rounded-end pe-5" type="text" placeholder="Search" /><i className="ci-search position-absolute top-50 end-0 translate-middle-y fs-sm me-3" />
                                    </div>
                                    <ul className="widget-list widget-filter-list list-unstyled pt-1" style={{ maxHeight: '11rem' }} data-simplebar="init" data-simplebar-auto-hide="false"><div className="simplebar-wrapper" style={{ margin: '-4px -16px 0px 0px' }}><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer" /></div><div className="simplebar-mask"><div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}><div className="simplebar-content-wrapper" style={{ height: 'auto', overflow: 'hidden scroll' }}><div className="simplebar-content" style={{ padding: '4px 16px 0px 0px' }}>
                                        
                                        <li className="widget-filter-item d-flex justify-content-between align-items-center mb-1">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="adidas" />
                                                <label className="form-check-label widget-filter-item-text" htmlFor="adidas">Adidas</label>
                                            </div><span className="fs-xs text-muted">425</span>
                                        </li>
                                        <li className="widget-filter-item d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="wrangler" />
                                                <label className="form-check-label widget-filter-item-text" htmlFor="wrangler">Wrangler</label>
                                            </div><span className="fs-xs text-muted">115</span>
                                        </li>

                                    </div></div></div></div><div className="simplebar-placeholder" style={{ width: 'auto', height: '870px' }} /></div><div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}><div className="simplebar-scrollbar simplebar-visible" style={{ width: '0px', display: 'none' }} /></div><div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}><div className="simplebar-scrollbar simplebar-visible" style={{ height: '35px', transform: 'translate3d(0px, 101px, 0px)', display: 'block' }} /></div></ul>
                                </div>

                            </div>
                        </div>
                    </aside>
                    {/* Content  */}
                    <section className="col-lg-8">
                        {/* Toolbar*/}
                        <div className="d-flex justify-content-end justify-content-sm-between align-items-center pt-2 mb-4 pb-sm-50 border-bottom">
                            <div className="d-flex flex-wrapx w-100">
                            
                                <div className="d-flex align-items-center justify-content-end flex-nowrap me-30 me-sm-4 pb-3">
                                    <label className="opacity-75 text-nowrap fs-sm me-2 d-none d-sm-block" htmlFor="sorting">Sort by:</label>
                                    <select className="form-select form-select-sm" id="sorting">
                                        <option>Popularity</option>
                                        <option>Low - Hight Price</option>
                                        <option>High - Low Price</option>
                                        <option>A - Z Order</option>
                                        <option>Z - A Order</option>
                                    </select>
                                </div>

                                <div className="d-flex align-items-center justify-content-start flex-nowrap pb-3">
                                    <span className="justify-content-start fs-sm opacity-90 text-nowrap d-none d-md-block">of 287 products</span>
                                </div>
                            </div>
                        </div>
                        {/* Products grid*/}
                        <div className="row mx-n2">
                            {/* Product*/}
                            
                            {   !loaded ? <div className='w-100 d-flex justify-content-center h-100 p-5' style={{ backgroundImage: `url(${require('../Assets/loading.gif').default})`, backgroundColor: '#f6f9fc', backgroundBlendMode: 'multiply'}}>
                                {/* <img className='spinner-borderx text-primary' stylea={{ width: '3rem', height: '3rem'}} role="status" src={ require('../Assets/loading.gif').default } /> */}
                                </div>
                                : products.map(p => <ProductCard col='col-4' product={p} key={p._id} wishListId={wishListId} setWishListId={setWishListId}/>)
                            }

                        </div>
                        {/* Banner*/}
                        {/* <div className="py-sm-2">
                            <div className="d-sm-flex justify-content-between align-items-center bg-secondary overflow-hidden mb-4 rounded-3">
                                <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">
                                    <h4 className="fs-lg fw-light mb-2">Converse All Star</h4>
                                    <h3 className="mb-4">Make Your Day Comfortable</h3><a className="btn btn-primary btn-shadow btn-sm" href="#">Shop Now</a>
                                </div><img className="d-block ms-auto" src="img/shop/catalog/banner.jpg" alt="Shop Converse" />
                            </div>
                        </div> */}
                        <div className="bg-light rounded-3 py-3 mt-4 my-5 border">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                            <div className="px-4 pe-sm-0 ps-sm-5"><span className="badge bg-danger">Limited Offer</span>
                                <h2 className="mb-1">All new Last Gen iPad Pro</h2>
                                <p className="h5 text-body fw-light">at discounted price. Hurry up!</p>
                                <a className="btn btn-accent" href="#">View offers<i className="ci-arrow-right fs-ms ms-1" /></a>
                            </div>
                            </div>
                            <div className="col-md-3"><img src="https://www.touchpoint.com.au/wp-content/uploads/2020/12/apple_new-ipad-air_new-design_09152020.jpg.news_app_ed.jpg" alt="iPad Pro Offer" /></div>
                        </div>
                        </div>
                        {/* Products grid*/}
                        <div className="row mx-n2 mt-3">
                            {/* Product*/}
                            {/* <div className="row mx-n20"> */}
                                {/* Product*/}
                                {   !loaded ? <div className='w-100 d-flex justify-content-center h-100 p-5' style={{ backgroundImage: `url(${require('../Assets/loading.gif').default})`, backgroundColor: '#f6f9fc', backgroundBlendMode: 'multiply'}}>
                                    {/* <img className='spinner-borderx text-primary' stylea={{ width: '3rem', height: '3rem'}} role="status" src={ require('../Assets/loading.gif').default } /> */}
                                    </div>
                                    : products.map(p => <ProductCard col='col-4' product={p} key={p._id} wishListId={wishListId} setWishListId={setWishListId}/>)
                                }
                            {/* </div> */}
                        </div>
                        <hr className="my-3" />


                        {/* Pagination*/}
                        <nav className="d-flex justify-content-between pt-2" aria-label="Page navigation">
                            <ul className="pagination">
                                <li className="page-item"><a className="page-link" href="#"><i className="ci-arrow-left me-2" />Prev</a></li>
                            </ul>
                            <ul className="pagination">
                                <li className="page-item d-sm-none"><span className="page-link page-link-static">1 / 5</span></li>
                                <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">1<span className="visually-hidden">(current)</span></span></li>
                                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">2</a></li>
                                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">3</a></li>
                                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">4</a></li>
                                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">5</a></li>
                            </ul>
                            <ul className="pagination">
                                <li className="page-item"><a className="page-link" href="#" aria-label="Next">Next<i className="ci-arrow-right ms-2" /></a></li>
                            </ul>
                        </nav>
                    </section>
                </div>
            </div>

        </>
    )
}

export default Shop
