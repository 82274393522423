import React from 'react'

function DescriptionInput({ state, setState }) {

    // const handleTypeDesc = (e) => {
    //     let valHTML = e   
    //     valHTML = valHTML.replace(/<div>/ig,"<br>");
    //     valHTML = valHTML.replace(/<\/div>/ig,"");
    //     valHTML = valHTML.replace(/<script>/ig,"");
    //     valHTML = valHTML.replace(/<\/script>/ig,"");
    //     valHTML = valHTML.replace(/<\/script>/ig,"");
    //     setState({ ...state, description: valHTML })
    // }

    // const handlePaste = (e) => {
    //     e.preventDefault()
    //     var text = e.clipboardData.getData("text/plain");
    //     e.target.innerHTML = text
    //     handleTypeDesc(text)
    // }

    const handleAreaInput = (e) => {
        // let valHTML = e.target.value
        // console.log(e.keyCode)
        // if(e.keyCode == 13) {
        //     valHTML += '<br/>'
        // }
        setState({ ...state, description: e.target.value })
    }

    return (
        <div className="mb-3 py-2">
            {/* <div className='bg-danger font-kh' style={{ whiteSpace: 'pre-wrap' }}>{ state.description }</div><br/> */}
            {/* <div dangerouslySetInnerHTML={{ __html: state.description }} className='bg-success font-kh' ></div><br/> */}
            {/* <pre className='bg-warning'>{ state.description }</pre><br/> */}
            <label className="form-label fw-bold" htmlFor="unp-product-description">Product description</label>

            {/* <div 
                // cols="100" rows="200"
                // readonly="readonly" v-html="content"
                className='w-100 h-100 p-3 border rounded bg-light textAreaSey'
                contenteditable="true"
                onInput={ (e) => handleTypeDesc(e.target.innerHTML) } 
                onBlur={ (e) => handleTypeDesc(e.target.innerHTML) }
                onKeyUp={ (e) => handleTypeDesc(e.target.innerHTML) }
                onPaste= { (e) => handlePaste(e) }
                // dangerouslySetInnerHTML={{ __html: state.description }}
            >
                {
                }
            </div> */}

            <textarea
                // hidden
                value={state.description}
                // onChange={ (e) => handleAreaInput(e) }
                onChange={ (e) => handleAreaInput(e) }
                className="form-control"
                rows={5}
                id="unp-product-description"
            />
        </div>
    )
}

export default DescriptionInput
