import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

function TagsInput({ state, setState }) {

    const { auth } = useSelector(s => ({ ...s }))
    const { token } = auth

    const [initialState, setInitialState] = useState([])
    const [displayTags, setDisplayTags] = useState([])
    const [existTag, setExistTag] = useState(false)
    const [tagInputValue, setTagInputValue] = useState('')

    // Load main category from server
    useEffect(() => {
        loadTag()
    }, [])

    const loadTag = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API}/tag/`)
        setInitialState(data)
        setDisplayTags(data)
        setTagInputValue('')
    }

    const addTagMarkUp = <>
        <label className="list-group-item-action dropdown-item pointer" onClick={ e => addNewTag(tagInputValue)}>
            <i className="ci-add-circle text-primary me-1"></i>
            <span><b className='text-primary'>Add</b> {tagInputValue}</span>
        </label>
        <hr className='mb-2 mt-2' />
    </>

    const handleSelectCheckBox = (e) => {
        const tag = e.target.getAttribute('datatag')
        if (e.target.checked) {
            setState({ ...state, organization: { ...state.organization, tags: [...state.organization.tags, tag] } })
        } else {
            let tempTags = [...state.organization.tags] // make a separate copy of the array
            let index = tempTags.indexOf(tag)
            if (index !== -1) {
                tempTags.splice(index, 1)
                setState({ ...state, organization: { ...state.organization, tags: [...tempTags] } })
            }
        }
        console.log('ochanfe', tag)
    }

    const handleRemoveTag = (e, tag) => {
        let tempTags = [...state.organization.tags] // make a separate copy of the array
        let index = tempTags.indexOf(tag)
        if (index !== -1) {
            tempTags.splice(index, 1)
            setState({ ...state, organization: { ...state.organization, tags: [...tempTags] } })
        }
    }

    const updateCheckBox = (tag) => {
        let typeLowcase = state.organization.tags.map(t => t.toLowerCase())
        const result = typeLowcase.includes(tag.toLowerCase())
        return result
    }

    const handleChangeTyping = (e) => {

        // e.preventDefault()

        // Get value on typing
        let val = e.target.value

        if(val.endsWith(',')) {
            e.target.value = ''
            setState({ ...state, organization: { ...state.organization, tags: [...state.organization.tags, val.slice(0, -1)] } })
            setDisplayTags(initialState)
            return
        }

        // filter on change
        setDisplayTags(initialState.filter(t => t.toLowerCase().startsWith(val.toLowerCase())))
        console.log(initialState.filter(t => t.toLowerCase().startsWith(val.toLowerCase())))
        // console.log('fininshed filter.', displayTags)

        // set value to state
        // setState({ ...state, organization: { ...state.organization, vendor: val } })
        setTagInputValue(val)
        // console.log('fininshed setTagInputValue.', val)


        // covert all types to lowercase
        const typeLowcase = initialState.map(t => t.toLowerCase())
        // console.log('fininshed typeLowcase.', typeLowcase)

        // if not have on db. Add new
        typeLowcase.includes(val.toLowerCase()) ? setExistTag(true) : setExistTag(false)
        // console.log('fininshed typeLowcase.includes.', typeLowcase.includes(val.toLowerCase()))
        // console.log(state)
    }

    // request to server to add new sub-category if not exist
    const addNewTag = async (val) => {

        let res = await axios.post(`${process.env.REACT_APP_API}/tag`, { name: val }, {
            headers: { Authorization: `bearer ${token}` },
            timeout: 3000
        })
        console.log(res);
        res && setInitialState(res.data)
        res && setDisplayTags(res.data)
        setState({ ...state, organization: { ...state.organization, tags: [...state.organization.tags, val] } })
        setTagInputValue(val)
        setExistTag(true)
    }

    return (
        <><div className="col-sm-6 mb-3 py-2">
            <div className="widget widget-filter">
                <label className="form-label fw-bold" htmlFor="unp-product-tags">Tags</label>
                <div className="input-group mb-2">
                    <input
                        onChange={e => handleChangeTyping(e)}
                        autoComplete="off" type="text"
                        className="widget-filter-search form-control rounded-end pe-5 dropdown-toggle"
                        data-bs-toggle="dropdown" placeholder="Vintage, cotton, summer"
                    />
                    <i className="ci-lable position-absolute top-50 end-0 translate-middle-y fs-sm me-3" />
                    
                    <div className={`dropdown-menu w-100 border border-primary mt-2`}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="list-group">
                            {!existTag && tagInputValue && addTagMarkUp}
                            <label className="dropdown-item text-uppercase text-muted fs-sm opacity-60"><span>Frequently used tags</span></label>
                            {
                                state && displayTags.map( (t, i) => (
                                    <label className="list-group-item-action dropdown-item pointer" key={i}>
                                        <input
                                            checked={updateCheckBox(t)}
                                            value={tagInputValue}
                                            onChange={ (e) => handleSelectCheckBox(e) } datatag={t}
                                            type="checkbox" id={`tags${i}`}
                                            className="form-check-input me-2"
                                            type="checkbox"
                                        /><span>{t}</span>
                                    </label>
                                ))
                            }
                        </div>
                    </div>
                    {/* <div className="form-text">Keywords that describe your item.</div> */}
                    {/* Tags should all be in lowercase and separated by commas. */}
                </div>

            </div>
        </div>

            <div className='col-sm-6 mb-3 py-2'>

                <div className="widget mt-3 w-100 sey-tag">
                    {
                        state.organization.tags.map((t, i) => (
                            <span className="btn-tag me-2 mb-2" key={i}>
                                #{t}
                                <button
                                    onClick={e => handleRemoveTag(e, t)}
                                    type="button" className="btn-close p-0 ms-1 mt-n1"
                                    data-bs-dismiss="alert" aria-label="Close"
                                    style={{ width: '5px !important', marginTop: '-5px !important' }}
                                />
                            </span>
                        ))
                    }
                </div>
            </div>
        </>)
}

export default TagsInput
