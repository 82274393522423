import axios from 'axios'

export const createWishList = async (token, idProduct) => await axios.get(`${process.env.REACT_APP_API}/wishlist/create/${idProduct}`, {
    headers: { Authorization: `bearer ${token}` },
    timeout: process.env.REACT_APP_AXIO_TIMEOUT
})

export const getWishList = async (token) => await axios.get(`${process.env.REACT_APP_API}/wishlist/`, {
    headers: { Authorization: `bearer ${token}` },
    timeout: process.env.REACT_APP_AXIO_TIMEOUT
})

export const removeWishList  = async (token, idProduct) => await axios.delete(`${process.env.REACT_APP_API}/wishlist/${idProduct}`, {
    headers: { Authorization: `bearer ${token}` },
    timeout: process.env.REACT_APP_AXIO_TIMEOUT
})