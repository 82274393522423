import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { numberValidate, limitLength } from '../Hooks/FormValidation'
import { requestVerifyCode } from '../Actions/Auth'
import PhoneVerifyModal from '../Components/PhoneVerifyModal'

function AccountRegisterLogin() {

    const history = useHistory()

    const { auth } = useSelector( state => ({...state}))
    useEffect(() => {
        window.scrollTo(0, 0)
        // console.log('UseEffect', auth)
        if(auth?.token && auth?.user) return history.push('/')
    }, [])

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')

    const [showPwd, setShowPwd] = useState(false)
    const [showVerify, setShowVerify] = useState(false)
    const [errMessage, setErrMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        // console.log('press submit on register');
        e.preventDefault();
    }

    const getCode = async (e) => {
        setLoading(true)
        setErrMessage('')
        if (phone == '' || phone.length < 8) return console.log('Phone number is requier')
        let { data, error } = await requestVerifyCode({ name, password, phone: phone.replace(/^0+/, '+855')})

        // code is sent to your phone
        // console.log(data);
        // console.log(error);
        if ( data?.success) {
            setLoading(false)
            return setShowVerify(!showVerify)
        } else if ( data.error?.code === 60203 ) {
            setErrMessage('You have attempted to send the verification code more than 5 times and have reached the limit. Please wait a moment to try again.')
            return setLoading(false)
        }
        setErrMessage(data.message)
        setLoading(false)
    }

    const phoneChange = (e) => {
        limitLength(e, 10)
        setPhone(e.target.value)
    }

    return (
        <>
            { showVerify &&
                <PhoneVerifyModal
                    showToggle={setShowVerify}
                    show={showVerify}
                    data={{ phone: phone.replace(/^0+/, '+855'), name, password }}
                />}

            {/* Body of account signin */}
            <div className="container py-4 py-lg-5 my-2 mt-4">
                <div className="row">
                    {/* <pre>{JSON.stringify({fname, phone, pwd, remember}, null, 4)}</pre> */}
                    <div className="col-md-6 offset-md-3">
                        <div className="card border-0 p-4 pt-2 pb-2 shadow">
                            <div className="card-body">

                                <h2 className="h4 pb-0 pt-3 text-centers fw-bold">No account? Register</h2>
                                <p className="fs-sm text-muted mb-4 pe-10">Registration takes less than a minute, but gives you full control over your orders and shop.</p>
                                <form className="needs-validation" noValidate onSubmit={handleSubmit}>

                                    <div className="input-group mb-3">
                                        <i className='ci-user position-absolute top-50 translate-middle-y text-muted fs-base ms-3' />
                                        {/* <label className="form-label" htmlFor='reg-fn'>Full Name</label> */}
                                        <input onChange={(e) => setName(e.target.value)}
                                            value={name}
                                            minLength='3' maxLength='36'
                                            className="form-control rounded-start"
                                            type='text'
                                            placeholder='Seyha Ei'
                                            required id='reg-fn' />
                                        {/* <div className="invalid-feedback">Please enter your full name!</div> */}
                                    </div>


                                    {/* <div className="row"> */}
                                    <div className="input-group mb-3">
                                        <i className="ci-mobile position-absolute top-50 translate-middle-y text-muted fs-base ms-3" />
                                        {/* <label className="form-label" htmlFor={errId}>{title}</label> */}
                                        <input value={phone} onKeyDown={numberValidate} onChange={phoneChange} className="form-control rounded-start" name='phone' type='number' placeholder='093575933' required id='reg-phone' />
                                        {/* <div className="invalid-feedback">{errText}</div> */}
                                    </div>

                                    <div className="input-group mb-3">
                                        <i className="ci-locked position-absolute top-50 translate-middle-y text-muted fs-base ms-3" />
                                        <div className="password-toggle w-100">
                                            {/* <label className="form-label" htmlFor="reg-password">Password</label> */}
                                            <input value={password} onChange={(e) => setPassword(e.target.value)} minLength="6" className="form-control" type={showPwd ? 'text' : 'password'} placeholder="Password" required />
                                            <div className="invalid-feedback">Please enter password!</div>
                                            <label className="password-toggle-btn" aria-label="Show/hide password">
                                                <input onChange={() => setShowPwd(!showPwd)} defaultChecked={showPwd} className="password-toggle-check" type="checkbox" id="reg-password" required /><span className="password-toggle-indicator" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-between">
                                        <small className='text-muted'>Already have an account?
                                        <Link className="nav-link-inline fs-sm" to='/login'> Login <i className="ci-sign-in me-2 ms-n21"></i></Link></small>
                                    </div>

                                    <hr className="mt-4" />
                                    <div className="text-end pt-4 pb-4">
                                        <button onClick={getCode} disabled={!(name.length >= 4 && phone.length >= 9 && password.length >= 6) || loading} className="btn btn-primary d-block w-100" type="submit">
                                            { loading 
                                            ? <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</>
                                            : <><i className="ci-add-user me-2 ms-n21" />Next Step)</> }
                                        </button>
                                    </div>
                                    <p className='fs-sm text-center pb-2 text-danger'>{errMessage}</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AccountRegisterLogin
