import React from 'react'

function TrackQuantityCheckbox({ state, setState }) {

    const handleQtyCount = (e) => {
        setState({ ...state, inventory: { ...state.inventory, trackQty: e.target.checked, quantity: 0, sellOnOutStock: true }})
    }

    return (
        <div className="form-check">
            <input
                onChange={(e) =>  handleQtyCount(e) }
                // defaultChecked={ state && state._id ? state.inventory.trackQty : true }
                checked={ state.inventory.trackQty }
                className="form-check-input" type="checkbox" id="ex-check-3" />
            <label className="form-check-label" htmlFor="ex-check-3">Track quantity g {`${state.inventory.trackQty}`}</label>
        </div>
    )
}

export default TrackQuantityCheckbox
