import React, { useState } from 'react'

function ProductGallery({ proId, media, title, image }) {

    const [preview, setPreview] = useState('')

    const handleClick = (e, src) => {
        e.preventDefault();
        setPreview(src)
    }

    return (
        <>
            <div className="product-gallery0 gallery">
                <div className="product-gallery-preview0 order-sm-2">
                    <div className="product-gallery-preview-item active pt-5 pb-5" id="first">
                        <img className="image-zoom img-fluid img cursor-default" src={preview === '' ? `${process.env.REACT_APP_API_FILES}/images/${proId}/${image}` : preview } alt={title} />
                        {/* <div className="image-zoom-pane" /> */}
                    </div>
                    {/* {
                        media.map((m, i) => (m !== null && m !== undefined && m !== '') &&
                            <div className="product-gallery-preview-item" id={`media${i}`} key={i}>
                                <img className="image-zoom" src={`${process.env.REACT_APP_API}/product/media/${proId}&${i}`} alt="Product image" />
                                <div className="image-zoom-pane" />
                            </div>
                        )
                    } */}
                </div>

                <div className="product-gallery-thumblist order-sm-1 d-flex">
                    <a onClick={ e => handleClick(e, `${process.env.REACT_APP_API_FILES}/images/${proId}/${image}`) } className="product-gallery-thumblist-item active pointer">
                        <img src={`${process.env.REACT_APP_API_FILES}/images/${proId}/${image}`} alt={title} />
                    </a>
                    {
                        media.map((m, i) => m !== null && m !== undefined && m !== '' &&
                            <a onClick={ e => handleClick(e, `${process.env.REACT_APP_API_FILES}/images/${proId}/${m}`) } className="product-gallery-thumblist-item pointer" key={i}>
                                <img className='img img-fluid h-100' src={`${process.env.REACT_APP_API_FILES}/images/${proId}/${m}`} alt={title} />
                            </a>
                        //  <a onClick={ e => handleClick(e, `${process.env.REACT_APP_API}/product/media/${proId}&${i}`) } className="product-gallery-thumblist-item pointer" key={i}>
                        //     <img src={`${process.env.REACT_APP_API}/product/media/${proId}&${i}`} alt={title} />
                        // </a>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default ProductGallery
