import React from 'react'

function ImageUploadItem({ padding, keyItems, state, setState, imgPreview, setImgPreview }) {

    const handleChangeImage = (e) => {

        let tempMedia = [...imgPreview.media]
        let filesLength = e.target.files.length

        let tempState = [ ...state.media ]

        // While user just update only exact coloum
        if (filesLength === 1) {

            if (e.target.files[0].size > 1e6) {
                window.alert("Please upload a file smaller than 1MB");
                return false;
            }

            tempMedia[keyItems] = URL.createObjectURL(e.target.files[0])
            setImgPreview({ ...imgPreview, media: [...tempMedia] })

            tempState[keyItems] = e.target.files[0]
            setState({ ...state, media: [...tempState] })
            return
        }

        for(let i = 0; i<( filesLength >= 8 ? 8 : filesLength); i++) {
            
            // console.log(`Check with file ${i}`);
            if (e.target.files[i].size > 1e6) return

            // if on this index coloum not have add new
            if (tempMedia[i] === '' || tempMedia[i] === undefined) {
                tempMedia[i] = (URL.createObjectURL(e.target.files[i]))
                tempState[i] = e.target.files[i]
                continue
            } else {
                // console.log(`Starting else on file: ${i}`);
                // Contine on this index+1 loop coloum not have add new
                for (let j = i+1; j < ( filesLength >= 8 ? 8 : filesLength ); j++) {
                    // console.log(`Contine on this index+1 loop coloum not have add new & j=${j}`);
                    if (tempMedia[j] === '' || tempMedia[j] === undefined) {
                        tempMedia[j] = (URL.createObjectURL(e.target.files[i]))
                        tempState[j] = e.target.files[i]
                        // i = j
                        break
                    } else {
                        continue
                    }
                }
            }

        } 

        // Update Preview image
        setImgPreview({ ...imgPreview, media: [...tempMedia] })
        setState({ ...state, media: [...tempState] })
    }

    const removeImage = (e, key) => {
        // console.log(key);
        let tempImg = [...imgPreview.media] // make a separate copy of the array
        tempImg[key] = ''
        setImgPreview({ ...imgPreview, media: [...tempImg] })

        let tempState = [ ...state.media ]
        tempState[keyItems] = ''
        setState({ ...state, media: [...tempState] })
    }

    return (
        <div className={`col-lg-12 h-50 ${padding}`}>
            <div className="position-relative h-100 rounded border bg-faded-dark pointerX file-drop-area overflow-hidden">             
                {
                    imgPreview.media
                    && imgPreview.media[keyItems] !== null
                    && imgPreview.media[keyItems] !== ''
                    && imgPreview.media[keyItems] !== undefined &&
                    <>
                        <span onClick={ e => removeImage(e, keyItems) } className="removeFile badge bg-primary rounded-pill badge-shadow position-absolute mt-1 me-1 top-0 end-0 p-1" style={{ fontSize: '.5em',zIndex: 999}} data-bs-dismiss="alert" aria-label="Close">
                            <i className='ci-close p-1'></i>
                        </span>
                        <img src={imgPreview.media[keyItems]} alt='' className='position-absolute top-0 start-0 img img-fluid rounded' style={{ height: 'auto', minHeight: '100px', width: 'auto' }} />
                    </>
                }
                <div className='align-self-center h-100 d-flex align-items-center'>
                    <span className='text-center w-100 mb-0 h5'>
                        <i className='ci-add text-muted'></i>
                    </span>
                    <input
                        onChange={handleChangeImage}
                        multiple
                        type="file" accept='image/*' name='image'
                        className="file-drop-input pointerX"
                        title='Product Image'
                    />
                </div>
            </div>
        </div>
    )
}

export default ImageUploadItem
