import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router"

function SuperAdmin({ ...rest }) {
    const { auth } = useSelector( state => ({ ...state}) )
    return (
        auth?.token && auth?.user && auth.user.type === 'SuperAdmin' ? <Route {...rest} /> : <Redirect to='/profile' />
    )
}

export default SuperAdmin
