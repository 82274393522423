import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Toast from '../Components/Toast'

function Header() {

    const { auth } = useSelector( state => ({...state}))
    const loggedIn = auth?.token && auth?.user
    const history = useHistory()

    const [showToast, setShowToast] = useState(false)

    const state = useSelector( state => ({...state}) )
    const [category, setCategory] = useState([])

    useEffect(() => {
        loggedIn && ( +Date.now() > auth.user.time + 1*864e5 ) && history.push('/logout')
    }, [])

    const handleSelectCat = () => {
        !category?.length && state?.category && setCategory([...state.category])    
    }

    const handleLogin = e => {
        if (!loggedIn) {
            e.preventDefault(); 
            setShowToast(true)
        }
    }

    return (
        <>
            {
                showToast && <Toast message='Please login to continue!' setShowToast={setShowToast} /> 
            }
            {/* Navbar 3 Level (Light)*/}
            <header className="shadow-sm">
                {/* Topbar*/}
                <div className="topbar topbar-dark bg-dark d-none d-sm-block">
                    <div className="container">
                        {/* <div className="topbar-text dropdown d-md-none float-end"><a className="topbar-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Useful links</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="tel:+85593575933"><i className="ci-support text-muted me-2" />(+855) 093 575 933</a></li>
                                <li><a className="dropdown-item" href="order-tracking.html"><i className="ci-location text-muted me-2" />Order tracking</a></li>
                            </ul>
                        </div> */}
                        <div className="topbar-text text-nowrap d-none d-md-inline-block">
                            {/* <i className="ci-support" /><span className="text-muted me-1">Support</span>
                            <a className="topbar-link" href="tel:+85593575933">(+855) 093 575 933</a> */}
                            {/* Free delivery for all orders over $200 */}
                            Welcome to Daklouk.com | <span className='kh-font'>ស្វាគមន៍មកកាន់ ដាក់លក់ដត់ខំ</span>
                            {/* Find it, love it, buy it. */}
                        </div>
                        {/* <div className="tns-carousel tns-controls-static d-none d-md-block">
                            <div className="tns-carousel-inner" data-carousel-options="{&quot;mode&quot;: &quot;gallery&quot;, &quot;nav&quot;: false}">
                                <div className="topbar-text">Free shipping for order over $200</div>
                                <div className="topbar-text">We return money within 30 days</div>
                                <div className="topbar-text">Friendly 24/7 customer support</div>
                            </div>
                        </div> */}
                        <div className="ms-3 text-nowrap">
                            <div className="topbar-text text-nowrap d-none d-md-inline-block"><i className="ci-support text-muted" /><span className="text-muted me-1">Support</span><a className="topbar-link" href="tel:+85593575933">(+855) 093 575 933</a></div>
                            {/* <a className="topbar-link me-4 d-none d-md-inline-block" href="order-tracking.html">
                                <i className="ci-location" />Order tracking
                            </a> */}
                            {/* <div className="topbar-text dropdown disable-autohide"><a className="topbar-link dropdown-toggle" href="#" data-bs-toggle="dropdown"><img className="me-2" src="img/flags/en.png" width={20} alt="English" />English</a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li className="dropdown-item">
                                        <select className="form-select form-select-sm">
                                            <option value="usd">$ USD</option>
                                            <option value="eur">€ EUR</option>
                                            <option value="ukp">£ UKP</option>
                                            <option value="jpy">¥ JPY</option>
                                        </select>
                                    </li>
                                    <li><a className="dropdown-item pb-1" href="#"><img className="me-2" src="img/flags/fr.png" width={20} alt="Khmer" />Khmer</a></li>
                                    <li><a className="dropdown-item pb-1" href="#"><img className="me-2" src="img/flags/de.png" width={20} alt="Deutsch" />Deutsch</a></li>
                                    <li><a className="dropdown-item" href="#"><img className="me-2" src="img/flags/it.png" width={20} alt="Italiano" />Italiano</a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* Remove "navbar-sticky" class to make navigation bar scrollable with the page.*/}
                <div className="navbar-sticky bg-danger">
                    <div className="navbar navbar-expand-lg navbar-dark">
                        <div className="container">
                            <Link className="navbar-brand d-none d-sm-block flex-shrink-0" to="/">
                                <img src={ require('../Assets/logo-white.svg').default } width={234} alt="Daklouk" />
                            </Link>
                            <Link className="navbar-brand d-sm-none flex-shrink-0 me-2" to="/"><img src={ require('../Assets/logo_square.svg').default } width={45} alt="Daklouk" /></Link>
                            
                            
                            {/* Search Bar */}
                            <div className="input-group d-none d-lg-flex flex-nowrap mx-4"><i className="ci-search position-absolute top-50 start-0 translate-middle-y ms-3" />
                            <input className="form-control rounded-start w-100" type="text" placeholder="Search for products" />
                            <select className="form-select flex-shrink-0 pointer" style={{width: '10.5rem'}} onMouseOver={handleSelectCat}>
                                <option>All categories </option>
                                {
                                    category && category.map( (c, i) => (
                                        <option
                                            defaultValue={c._id} key={i}
                                            data-content={`<i class={${c.icon} me-2}></i>`}>
                                            {c.name} 
                                        </option>)
                                    )
                                }
                            </select>
                            </div>
                            
                            {/* <div className="input-group d-none d-lg-flex mx-4">
                                <input className="form-control rounded-end pe-5" type="text" placeholder="Search for products" /><i className="ci-search position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3" />
                            </div> */}
                            <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"><span className="navbar-toggler-icon" /></button><a className="navbar-tool navbar-stuck-toggler" href='#'><span className="navbar-tool-tooltip">Expand menu</span>
                                    <div className="navbar-tool-icon-box"><i className="navbar-tool-icon ci-menu" /></div></a><Link onClick={ e => handleLogin(e) } className="navbar-tool d-none d-lg-flex" to="/wishlist"><span className="navbar-tool-tooltip">Wishlist</span>
                                    <div className="navbar-tool-icon-box"><i className="navbar-tool-icon ci-heart" /></div></Link><Link onClick={ e => handleLogin(e) } className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" to='/profile' data-bs-toggle="modal">
                                    <div className="navbar-tool-icon-box"><i className="navbar-tool-icon ci-user" /></div>
                                    <div className="navbar-tool-text ms-n3">
                                        <small>Hello, { loggedIn ? auth.user.name : 'Guest' }</small>
                                        My Account</div></Link>
                                {/* <div className="navbar-tool dropdown ms-3"><a className="navbar-tool-icon-box bg-secondary dropdown-toggle" href="shop-cart.html"><span className="navbar-tool-label">4</span><i className="navbar-tool-icon ci-cart" /></a><a className="navbar-tool-text" href="shop-cart.html"><small>My Cart</small>$265.00</a>
                                    Cart dropdown
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <div className="widget widget-cart px-3 pt-2 pb-3" style={{ width: '20rem' }}>
                                            <div style={{ height: '15rem' }} data-simplebar data-simplebar-auto-hide="false">
                                                <div className="widget-cart-item pb-2 border-bottom">
                                                    <button className="btn-close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">×</span></button>
                                                    <div className="d-flex align-items-center"><a className="flex-shrink-0" href="shop-single-v1.html"><img src="img/shop/cart/widget/01.jpg" width={64} alt="Product" /></a>
                                                        <div className="ps-2">
                                                            <h6 className="widget-product-title"><a href="shop-single-v1.html">Women Colorblock Sneakers</a></h6>
                                                            <div className="widget-product-meta"><span className="text-accent me-2">$150.<small>00</small></span><span className="text-muted">x 1</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="widget-cart-item py-2 border-bottom">
                                                    <button className="btn-close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">×</span></button>
                                                    <div className="d-flex align-items-center"><a className="flex-shrink-0" href="shop-single-v1.html"><img src="img/shop/cart/widget/02.jpg" width={64} alt="Product" /></a>
                                                        <div className="ps-2">
                                                            <h6 className="widget-product-title"><a href="shop-single-v1.html">TH Jeans City Backpack</a></h6>
                                                            <div className="widget-product-meta"><span className="text-accent me-2">$79.<small>50</small></span><span className="text-muted">x 1</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="widget-cart-item py-2 border-bottom">
                                                    <button className="btn-close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">×</span></button>
                                                    <div className="d-flex align-items-center"><a className="flex-shrink-0" href="shop-single-v1.html"><img src="img/shop/cart/widget/03.jpg" width={64} alt="Product" /></a>
                                                        <div className="ps-2">
                                                            <h6 className="widget-product-title"><a href="shop-single-v1.html">3-Color Sun Stash Hat</a></h6>
                                                            <div className="widget-product-meta"><span className="text-accent me-2">$22.<small>50</small></span><span className="text-muted">x 1</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="widget-cart-item py-2 border-bottom">
                                                    <button className="btn-close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">×</span></button>
                                                    <div className="d-flex align-items-center"><a className="flex-shrink-0" href="shop-single-v1.html"><img src="img/shop/cart/widget/04.jpg" width={64} alt="Product" /></a>
                                                        <div className="ps-2">
                                                            <h6 className="widget-product-title"><a href="shop-single-v1.html">Cotton Polo Regular Fit</a></h6>
                                                            <div className="widget-product-meta"><span className="text-accent me-2">$9.<small>00</small></span><span className="text-muted">x 1</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                                                <div className="fs-sm me-2 py-2"><span className="text-muted">Subtotal:</span><span className="text-accent fs-base ms-1">$265.<small>00</small></span></div><a className="btn btn-outline-secondary btn-sm" href="shop-cart.html">Expand cart<i className="ci-arrow-right ms-1 me-n1" /></a>
                                            </div><a className="btn btn-primary btn-sm d-block w-100" href="checkout-details.html"><i className="ci-card me-2 fs-base align-middle" />Checkout</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="navbar navbar-expand-lg navbar-dark navbar-stuck-menu mt-n2 pt-0 pb-2">
                        <div className="container">
                            <div className="collapse navbar-collapse" id="navbarCollapse">
                                {/* Search*/}
                                <div className="input-group d-lg-none my-3"><i className="ci-search position-absolute top-50 start-0 translate-middle-y text-muted fs-base ms-3" />
                                    <input className="form-control rounded-start" type="text" placeholder="Search for products" />
                                </div>
                                {/* Departments menu*/}
 
                                {/* Primary menu*/}
                                <ul className="navbar-nav">
                                    <li className="nav-item"><Link className="nav-link dropdown-toggle" to='/'>Home</Link></li>
                                    <li className="nav-item"><Link className="nav-link dropdown-toggle" to='/shop'>Shop</Link></li>
                                    <li className="nav-item"><Link className="nav-link dropdown-toggle" to='/'>New Arrival</Link></li>
                                    <li className="nav-item"><Link className="nav-link dropdown-toggle" to='/'>Discounts <span className="badge bg-light">Sale</span></Link></li>
                                    { loggedIn
                                    ?   <>
                                            <li className="nav-item dropdown"><Link className="nav-link dropdown-toggle" to='/add-product'>Dashboard</Link></li>
                                            <li className="nav-item"><Link to='/logout' className="nav-link dropdown-toggle pointer">Logout</Link></li>                
                                            {/* <li className="nav-item"><Link onClicked={ e => { e.preventDefault(); logout()} } to='logout' className="nav-link dropdown-toggle pointer">Logout</Link></li>             */}
                                        </>
                                    :   <>
                                            <li className="nav-item"><Link className="nav-link dropdown-toggle" to='/login'>Login</Link></li>
                                            <li className="nav-item"><Link className="nav-link dropdown-toggle" to='/register'>Register</Link></li>
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
