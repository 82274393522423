import React, { useState, useEffect } from 'react'
import Dashboard from '../Dashboard'
import { useSelector } from 'react-redux'
import { updateProduct, singleEditProduct } from '../../Actions/Product'

// Componenent
import ContinueSellOutStockCheckbox from '../../Components/AddProduct/ContinueSellOutStockCheckbox'
import IsPhysicalCheckbox from '../../Components/AddProduct/IsPhysicalCheckbox'
import CostInput from '../../Components/AddProduct/CostInput'
import DescriptionInput from '../../Components/AddProduct/DescriptionInput'
import DiscountInput from '../../Components/AddProduct/DiscountInput'
import ImageInput from '../../Components/AddProduct/ImageInput'
import PriceInput from '../../Components/AddProduct/PriceInput'
import TagsInput from '../../Components/AddProduct/TagsInput'
import TitleInput from '../../Components/AddProduct/TitleInput'
import TypeInput from '../../Components/AddProduct/TypeInput'
import VendorInput from '../../Components/AddProduct/VendorInput'
import PublicNowToggle from '../../Components/AddProduct/PublicNowToggle'
import QuantityInput from '../../Components/AddProduct/QuantityInput'
import SKUInput from '../../Components/AddProduct/SKUInput'
import TrackQuantityCheckbox from '../../Components/AddProduct/TrackQuantityCheckbox'
import CategorySelect from '../../Components/AddProduct/CategorySelect'

function EditProduct({ match }) {

    const { auth } = useSelector(state => ({ ...state }))
    const { token } = auth

    let tempPro = {}

    useEffect(() => {
        singleEditProduct(token, match.params.id).then( res => {
            // setProduct(res.data)
            tempPro = Object.assign(state, res.data)
            setState( state => ({
                ...tempPro,
                organization: {
                    ...tempPro.organization,
                    vendor: res.data.organization.vendor._id,
                    productType: res.data.organization.productType._id
                },
                // media: [ ...res.data.media.map( m => m != null ? m : '' ) ],
                mainCategory: res.data.organization.productType.idMain,
                subCat: res.data.organization.productType.name,
                brand: res.data.organization.vendor.name
            }))
            console.log('Effect', state)
        }
            
        )
    }, [match.params.id])

    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        title: '',
        description: '',
        status: true, // default public [private]
        mainCategory: '',
        organization: {
            productType: '', // powerbank, battery // it ID
            vendor: '', // like brand // it ID
            tags: []
        },
        featureImage: '',
        media: [],
        pricing: {
            price: 0,
            discount: 0, // default = null
            costPrice: null // default = null
        },
        inventory: {
            sku: '',
            isPhysicalProduct: true,
            trackQty: true,
            sellOnOutStock: true, // if track qty is true only. else false
            quantity: 1  // if track qty is true only. else set it null
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('clcic');

        // setLoading(true)
        let productData = new FormData()

        // Feature Image
        state.featureImage && productData.append('featureImage', state.featureImage)

        // Media
        state.media && state.media.map((f, i) => productData.append(`media${i}`, f))

        // Detail
        productData.append('title', state.title)
        productData.append('description', state.description)
        productData.append('status', state.status)

        // Oraganization
        productData.append('productType', state.organization.productType)
        productData.append('vendor', state.organization.vendor)
        productData.append('tags', state.organization.tags)

        // Pricing
        productData.append('price', state.pricing.price)
        productData.append('discount', state.pricing.discount)
        productData.append('costPrice', state.pricing.costPrice)

        // Inventory
        productData.append('sku', state.inventory.sku)
        productData.append('isPhysicalProduct', state.inventory.isPhysicalProduct)
        productData.append('trackQty', state.inventory.trackQty)
        productData.append('sellOnOutStock', state.inventory.sellOnOutStock)
        productData.append('quantity', state.inventory.quantity)

        // console.log([...productData])
        try {
            let res = await updateProduct(token, state._id, productData)
            console.log('Product Edit RES ===> ', res.data.data._id)

            // window.location.href = `http://localhost:8000/api/product/image/${res.data.data._id}`

            setTimeout( () => {
                window.location.reload()
            }, 1000)

        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    return (
        <Dashboard>
        <section className="col-lg-8 pt-lg-4 pb-4 mb-3 addProduct">
            <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                <div className="d-sm-flex flex-wrap justify-content-between align-items-center pb-2">
                    <h2 className="h3 py-2 me-2 text-center text-sm-start">Edit Product</h2>
                    {/* <PublicNowToggle state={state} setState={setState} /> */}
                    <CategorySelect state={state} setState={setState} />
                </div>
                <form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data">
                    <TitleInput state={state} setState={setState} />
                    <DescriptionInput state={state} setState={setState} />
                    <div className="row">
                        <PriceInput state={state} setState={setState} />
                        <DiscountInput state={state} setState={setState} />
                        <CostInput state={state} setState={setState} />
                    </div>
                    <ImageInput state={state} setState={setState} />
                    <div className="row">
                        <TypeInput state={state} setState={setState} />
                        <VendorInput state={state} setState={setState} />
                        <TagsInput state={state} setState={setState} />
                    </div>

                    <div className="row">
                        <SKUInput state={state} setState={setState} />
                        {
                            state.inventory.trackQty &&
                            <QuantityInput state={state} setState={setState} />
                        }
                    </div>
                    <div className="row">
                        <div className="col-sm-6 mb-0 pt-3">
                            <IsPhysicalCheckbox state={state} setState={setState} />
                            <TrackQuantityCheckbox state={state} setState={setState} />
                        </div>
                        <div className="col-sm-6 mb-0 pt-3">
                            {
                                state.inventory.trackQty &&
                                <ContinueSellOutStockCheckbox state={state} setState={setState} />
                            }
                        </div>
                        <div className='col-md-12'>
                        <PublicNowToggle state={state} setState={setState} />
                        </div>
                    </div>
                    <button onClick={e => handleSubmit} disabled={loading} className="btn btn-primary d-block w-100 mt-4">
                        {loading
                            ? <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</>
                            : <><i className="ci-cloud-upload fs-lg me-2" />{ state && state._id ? 'Edit Product' : 'Upload Product'}</>}
                    </button>
                </form>
            </div>
            {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
        </section>
        </Dashboard>
    )
}

export default EditProduct
