import React from 'react'
import { Link } from 'react-router-dom'

function PaymentSuccess(props) {

    console.log(props)

    return (
        <>
            <div className="container pb-5 mb-sm-4">
                <div className="pt-5">
                    <div className="card py-3 mt-sm-3">
                        <div className="card-body text-center">
                            <h2 className="h4 pb-3 fw-bold">Thank you for your order!</h2>
                            <p className="mb-2">Your order has been placed and will be processed as soon as possible.</p>
                            <p className="mb-2">Make sure you make note of your order number, which is <span className="fw-medium">34VB5540K83.</span></p>
                            <p className="">You will be receiving an email shortly with confirmation of your order. <u>You can now:</u></p>
                            <Link className="btn btn-danger mt-3 me-3" to="/">Go back shopping</Link>
                            {/* <a className="btn btn-primary mt-3" href="order-tracking.html"><i className="ci-location" />&nbsp;Track order</a> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PaymentSuccess
