import { createPortal } from "react-dom";

function Modal({ show, showToggle, children, size='modal-dialog-centered' }) {

    const modalMarkUp = (
        <div className="modal fade show" id={size} tabIndex={-1} style={{ display: 'block', paddingRight0: '19px', backgroundColor: 'rgb(0 0 0 / 70%)' }} aria-modal="true" role="dialog">
            <div className={`modal-dialog ${size} modal-dialog-scrollable modal-dialog-centered`} role="document">
                <div className="modal-content rounded-3" >
                    <button onClick={ () => showToggle(!show)} type="button" className="m-3 btn btn-close p-2 rounded-pill position-absolute" style={{zIndex: 1}}/>
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
        </div >
    )

    return createPortal(
        <>{modalMarkUp}</>,
        document.getElementById("modal_root"),
    );

}

export default Modal;