import React, { useState, useEffect } from 'react'
import { singleProduct, viewCount } from '../Actions/Product'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ProductGallery from '../Components/ProductGallery'
import CheckOut from './CheckOut'
import { currencyFormarter } from '../Hooks/CurrencyFormart'
import YouMayLike from '../Components/YouMayLike'
import { useTitleHook } from "../Hooks/TitleHook"

function ProductDetail(props) {

    const { match, history } = props
    // console.log(props)
    const { auth } = useSelector( state => ({...state}))
    // const history = useHistory()

    const [hookTitle, setHookTitle] = useTitleHook(process.env.REACT_APP_SITE_TITLE);

    const [product, setProduct] = useState({})

    const [showCheckOut, setShowCheckOut] = useState(false)

    useEffect(() => {
        // console.log('props.location.state is:', props.location.state);
        singleProduct(match.params.id).then(res => {
            setProduct(res.data)
            res.data?.title && setHookTitle(res.data.title)
        })      
        viewCount(match.params.id)
        return () => {
            setHookTitle(process.env.REACT_APP_SITE_TITLE)
        };
    }, [])

    // console.log(product);

    const handleCheckOutModal = () => {
        if (auth?.token && auth?.user){
            setShowCheckOut(true)
            return
        }
        // history.push('/login', {url:match.url})
        // console.log(match);
        // return history.pus({
        //     pathname: `/login`,
        //     // search: `${match.params.id}`,
        //     customNameData: match.url
        // })
        return history.push( '/login', { callbackUrl: match.url } )
        
    }


    const url = `https://www.daklouk.com${match.url}`

    const fbShareLink = () => {
        let link = `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FB_APP_ID}&display=popup&href=${url}&redirect_uri=${url}`
        window.open(link,'popup','width=600,height=600,scrollbars=no,resizable=no')
    } 

    return (
        <>  
            { showCheckOut &&
                <CheckOut
                    showToggle={setShowCheckOut}
                    show={showCheckOut}
                    data={product}
            />}
            { product?.title &&
            <main >
                {/* Advertising */}
                <div className="col-md-12 mt-2 mb-2">
                    <div className="d-flex flex-column h-100 justify-content-center bg-faded-success bg-size-cover bg-position-center border rounded-3" style={{backgroundImage: 'url()'}}>
                        <div className="py-4 my-2 px-4 text-center">
                        <div className="py-2">
                            <h5 className="mb-2 kh-font mt-1">ទាក់ទងផ្សាយពាណិជ្ចកម្ម | Your Ads Banner Here</h5>
                            <p className="fs-sm text-muted">Hurry up to reserve your spot</p>
                            <h3 className='fw-bold mb-0 text-accent'><i className='ci-mobile'/> +855 93 575 933</h3>
                        </div>
                        </div>
                    </div>
                </div>

                <section className="pt-4">
                    <div className="">
                        {/* Page title + breadcrumb*/}
                        <nav className="mb-4" aria-label="breadcrumb">
                            <ol className="breadcrumb flex-lg-nowrap fs-sm text-truncate">
                                <li className="breadcrumb-item"><Link className="text-nowrap" to='/'><i className="ci-home" />Home</Link></li>
                                <li className="breadcrumb-item text-nowrap"><a href="#">{ product?.organization.productType.name ?? ''}</a>
                                </li>
                                {/* <li className="breadcrumb-item text-nowrap active text-truncate kh-font" aria-current="page">{product.title ?? ''}</li> */}
                            </ol>
                        </nav>
                        {/* Content*/}
                        {/* Product Gallery + description*/}

                        <section className="row g-0 mx-n2 pb-5 mb-xl-3">
                            <div className="col-xl-7 px-2 mb-3">
                                <div className="h-100 bg-light rounded-3 px-5 py-50 pb-5 border">
                                    {/* <div className="gallery"><a className="rounded-3 mb-grid-gutter"><img className='px-5' src={`${process.env.REACT_APP_API}/product/image/${product._id}`} alt="Gallery preview" /></a>
                                        <div className="row px-3 d-flex justify-content-center">
                                            {
                                                product.media.map((m, i) => 
                                                <div className="col-sm-1 order-sm-1 m-1 p-0"><a className="gallery-item rounded-3 mb-grid-gutterxx border opacity-70" ><img src={`${process.env.REACT_APP_API}/product/media/${product._id}&${i}`} alt="Gallery preview" /></a></div>
                                                )
                                            }
                                        </div>
                                    </div> */}
                                    <ProductGallery proId={product._id} media={product.media} title={product.title} image={product.featureImage}/>
                                

                                    {/* <h6 className='fw-bold mt-5 pt-5 border-top'>Product description</h6>
                                    <ul className="list-unstyled fs-sm pt-2 mb-0 pb-3 ">
                                        <p className='fs-md' style={{ lineHeight: '1.5rem', whiteSpace: 'pre-wrap' }}>{product.description}</p>
                                    </ul> */}
                                </div>
                            </div>

                            <div className="col-xl-5 px-2 mb-3">
                                <div className="h-100 bg-light rounded-3 py-5 px-4 px-sm-5 border">
                                    <span className="badge bg-accent mb-3">{ product.organization.vendor.name ?? ''}</span>
                                    {/* <a className="product-meta d-block fs-sm pb-2" href="#">{product.organization.vendor ?? ''}</a> */}
                                    <h1 className="h4 fw-bold kh-font line-height-2">{product.title}</h1>
                                    <div><button href="#" className="btn-tag btn-sm bg-faded-dark me-2 mb-2 kh-font" disabled><i className='ci-eye' /> {product.viewed}</button></div>
                                    
                                    {/* <div className="h3 fw-bold text-danger">
                                        ${product.pricing.discount > 0 ? product.pricing.price-product.pricing.discount : product.pricing.price}
                                    </div> */}
                                    <div data-v-7dc09a7e className="mb-3">
                                        <span data-v-7dc09a7e className="h3 fw-bold text-danger">{product.pricing.discount > 0 ? currencyFormarter({amount: product.pricing.price-product.pricing.discount}) : currencyFormarter({amount: product.pricing.price})} </span>
                                        { product.pricing.discount > 0 && <del data-v-7dc09a7e className="text-muted font-size-lg mr-3"> {currencyFormarter({amount: product.pricing.price})}</del> }
                                    </div>


                                    
                                    <div className="d-flex flex-wrap align-items-center pt-4 pb-2 mb-3">
                                        {   product.inventory.trackQty
                                            && product.inventory.quantity > 0 &&
                                            <select className="form-select me-3 mb-3 " style={{ width: '5rem' }}>
                                                { [...Array(product.inventory.quantity)].map((e, i) => <option value={i+1} key={i+1}>{i+1}</option> ) }
                                            </select>
                                        }
                                        <button onClick={ handleCheckOutModal } className={`btn btn-danger mb-3 btn-block ${ !product.inventory.trackQty ? 'w-100' : 'flex-fill'}`}><i className="ci-card fs-lg me-2" />Checkout Now</button>
                                        {/* <WishListButton productId={product._id} /> */}
                                    </div>
                                    <h6 className='fw-bold'>Product description</h6>
                                    <ul className="list-unstyled fs-sm pt-2 mb-0 pb-3 border-bottom">
                                        <p className='fs-md' style={{ lineHeight: '1.5rem', whiteSpace: 'pre-wrap' }}>{product.description}</p>
                                    </ul>
                                    {/* Widget: Tag cloud */}
                                    <div className="widget mt-4 mb-4">
                                        <h3 className="widget-title fw-bold">Product tags</h3>
                                        {
                                            product.organization &&
                                            (product.organization.tags).map( (value, i) =>
                                                <a href="#" className="btn-tag bg-faded-dark me-2 mb-2 kh-font" key={i}>#{value}</a>
                                            )
                                        }
                                    </div>

                                    <div>
                                        {/* Sharing*/}
                                        <label className="form-label d-inline-block align-middle my-2 me-3">Share:</label>
                                        <button onClick={fbShareLink} className="border-0 me-2 btn-share btn-facebook"><i className="ci-facebook" />Facebook</button>
                                        <a className="btn-share btn-twitter me-2 my-2" target='_blank' href={`https://twitter.com/intent/tweet?url=${url}`}><i className="ci-twitter" />Twitter</a>
                                        <a className="btn-share btn-telegram my-2" target='_blank' href={`https://telegram.me/share/url?url=${url}&text=${process.env.REACT_APP_SLOGAN}`}><i className="ci-telegram" />Telegram</a>
                                    </div>

                                    <div className='mt-3 d-flex justify-content-start'>
                                        <small className='text-muted'>We accept:</small>
                                        <img className='align-self-center align-middle mt-n1 ms-3' style={{ height: '30px' }} src={ require('../Assets/payment.png').default } />
                                    </div>

                                </div>
                            </div>
                        </section>

                    </div>
                </section>

                { auth?.token && auth?.user?.type === 'SuperAdmin' && <YouMayLike/>}
            </main>
        }
        </>
    )
}

export default ProductDetail
