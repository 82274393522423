import React from 'react'
import { Link } from 'react-router-dom'

function Dashboard({ children, url='', notifyCount=0, auth=null}) {

    return (
        <>
            {/* <DashboardHeader /> */}
            <div className="container mb-5 pb-3">
                <div className="bg-light shadow-lg rounded-3 overflow-hidden">
                    <div className="row">
                        {/* Sidebar*/}
                        <aside className="col-lg-4 pe-xl-5">
                            {/* Account menu toggler (hidden on screens larger 992px)*/}
                            {/* <div className="d-block d-lg-none p-4"><a className="btn btn-outline-accent d-block" href="#account-menu" data-bs-toggle="collapse"><i className="ci-menu me-2" />Account menu</a></div> */}
                            {/* Actual menu*/}
                            <div className="h-100 border-end mb-2">
                                <div className="d-lg-block collapse" id="account-menu">
                                    <div className="bg-secondary p-4">
                                        <h3 className="fs-sm mb-0 text-muted">Account</h3>
                                    </div>
                                    <ul className="list-unstyled mb-0">
                                        <li className="border-bottom mb-0"><Link className={`nav-link-style d-flex align-items-center px-4 py-3 ${ url === '/profile' && 'active' } `} to='/profile'><i className="ci-user opacity-60 me-2" />Profile info</Link></li>
                                        {/* <li className="border-bottom mb-0"><a className="nav-link-style d-flex align-items-center px-4 py-3" href="account-orders.html"><i className="ci-bag opacity-60 me-2" />Orders<span className="fs-sm text-muted ms-auto">1</span></a></li> */}
                                        <li className="border-bottom mb-0"><Link className={`nav-link-style d-flex align-items-center px-4 py-3 ${ url === '/wishlist' && 'active' } `} to='/wishlist'><i className="ci-heart opacity-60 me-2" />Wishlist<span className="fs-sm text-muted ms-auto">{notifyCount}</span></Link></li>
                                        {/* <li className="border-bottom mb-0"><a className="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-settings.html"><i className="ci-settings opacity-60 me-2" />Settings</a></li> */}
                                        {/* <li className="border-bottom mb-0"><a className="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-purchases.html"><i className="ci-basket opacity-60 me-2" />Purchases</a></li> */}
                                        {/* <li className="mb-0"><a className="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-favorites.html"><i className="ci-heart opacity-60 me-2" />Favorites<span className="fs-sm text-muted ms-auto">4</span></a></li> */}
                                    </ul>
                                    <div className="bg-secondary p-4">
                                        <h3 className="fs-sm mb-0 text-muted">Product Dashboard</h3>
                                    </div>
                                    <ul className="list-unstyled mb-0">
                                        {/* <li className="border-bottom mb-0"><a className="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-sales.html"><i className="ci-dollar opacity-60 me-2" />Sales<span className="fs-sm text-muted ms-auto">$1,375.00</span></a></li> */}
                                        {/* <li className="border-bottom mb-0"><a className="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-products.html"><i className="ci-package opacity-60 me-2" />Products<span className="fs-sm text-muted ms-auto">5</span></a></li> */}
                                        {/* <li className="border-bottom mb-0"><Link className="nav-link-style d-flex align-items-center px-4 py-3" to='/dashboard-category'><i className="ci-package opacity-60 me-2" />Main Category</Link></li> */}
                                        { auth?.token && auth?.user?.type === 'SuperAdmin' && 
                                            <li className="border-bottom mb-0"><Link className={`nav-link-style d-flex align-items-center px-4 py-3 ${ url === '/add-product' && 'active' } `} to="/add-product"><i className="ci-cloud-upload opacity-60 me-2" />Add New Product</Link></li>
                                        }
                                        {/* <li className="border-bottom mb-0"><a className="nav-link-style d-flex align-items-center px-4 py-3" href="dashboard-payouts.html"><i className="ci-currency-exchange opacity-60 me-2" />Payouts</a></li> */}
                                        {/* <li className="mb-0"><a className="nav-link-style d-flex align-items-center px-4 py-3" href="account-signin.html"><i className="ci-sign-out opacity-60 me-2" />Sign out</a></li> */}
                                    </ul>
                                    <hr />

    

                                </div>
                            </div>
                        </aside>
                        {children}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Dashboard
