import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

function Popular({ group, mainId }) {

    const dispatch = useDispatch()
    const state = useSelector( state => ({...state}) )

    const [subCategory, setSubCategory] = useState([])

    useEffect(() => {

        if (state.category) {
            let isLocal = false
            state.category.map( c => {
            if (c._id === mainId && c.sub) {
                // console.log('load sub from state', c.sub);
                setSubCategory([...c.sub])
                isLocal = true
            }})
            if (isLocal) return
        }
        loadSubCategory()
    }, [])

    const loadSubCategory = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API}/sub-categories/${mainId}`)
        setSubCategory(data)
        
        // Prepare to save sub-category to Redux
        state.category && state.category.map( c => {
            if (c._id === mainId) {
                c.sub = [...data]
                dispatch({
                    type: 'SET',
                    payload: [...state.category]
                })
            }
        })
        // console.log('load sub from in');
    }

    return (
        <>
           <div className="d-flex flex-wrap flex-md-nowrap px-2">

                <div className="mega-dropdown-column pt-4 pb-0 py-sm-4 px-3">
                    <div className="widget widget-links mb-3">
                        <h6 className="fs-base mb-3 fw-bold">{ group[0] }</h6>
                        <ul className="widget-list">
                            {
                                subCategory && subCategory.map( (s, i) => <li className="widget-list-item pb-1" key={i}><a className="widget-list-link" href="#">{s.name}</a></li>)
                            }
                            
                        </ul>
                    </div>
                    {/* { group.length > 2 &&
                    <div className="widget widget-links">
                        <h6 className="fs-base fw-bold">{ group[2] }</h6>
                        <ul className="widget-list">
                            <li className="widget-list-item pb-1"><a className="widget-list-link" href="#">Apple iPad</a></li>
                        </ul>
                    </div>
                    } */}
                </div>
                {/* { group.length > 1 &&
                <div className="mega-dropdown-column py-4 px-3">
                    <div className="widget widget-links">
                        <h6 className="fs-base mb-3 fw-bold">{ group[1] }</h6>
                        <ul className="widget-list">
                            <li className="widget-list-item pb-1"><a className="widget-list-link" href="#">Accessory Kits</a></li>
                        </ul>
                    </div>
                </div>
                } */}
                {/* <div className="mega-dropdown-column d-none d-lg-block py-4 text-center"><a className="d-block mb-2" href="#"><img src="img/shop/departments/09.jpg" alt="Smartphones & Tablets" /></a>
                    <div className="fs-sm mb-3">Starting from <span className="fw-medium">$127.<small>00</small></span></div><a className="btn btn-primary btn-shadow btn-sm" href="#">See offers<i className="ci-arrow-right fs-xs ms-1" /></a>
                </div> */}
            </div>

        </>
    )
}

export default Popular
