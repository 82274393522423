import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { createWishList, removeWishList } from '../Actions/WishList'
import { deleteProduct } from '../Actions/Product'
import Loading from '../Components/Loading'
import { currencyFormarter } from '../Hooks/CurrencyFormart'
import LoveFilled from './Icons/LoveFilled'
import Toast from './Toast'

function ProductCard({ product, wishListId, setWishListId, col='col-md-2' }) {

    // console.log('wishListId on ProductCard', wishListId);

    const { auth } = useSelector( state => ({...state}))
    const loggedIn = auth && auth.user && auth.token

    const dispatch = useDispatch()

    // const [preview, setPreview] = useState(`${process.env.REACT_APP_API_FILES}/images/${product._id}/${product.featureImage}`)
    // const [preview, setPreview] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const isSale = product.pricing.discount > 0

    const handleDelectProduct = async (e) => {
        e.preventDefault();
        if (!window.confirm('Are you sure to delete?')) return
        let { data } = await deleteProduct(loggedIn && auth.token, product._id)
        // console.log(res);
        data?.data.success && setTimeout( () => {
            window.location.reload()
        }, 2000)
    }

    const loadingHandle = () => {
        // setPreview(`${process.env.REACT_APP_API_FILES}/images/${product._id}/${product.featureImage}`)
        setLoaded(true)
        // console.log(loaded);
    }

    const handleWishList = async (e, id) => {

        e.preventDefault()
        if (loggedIn) {
            const result = await createWishList(auth.token, id);
            if(result.data.success) setWishListId([...wishListId, id])
            // console.log(result, auth?.token);
        } else setShowToast(true)
        
    }

    const deleteWishList = async (e, id) => {
        e.preventDefault()
        if (loggedIn) {
            const result = await removeWishList(auth?.token, id)
            if (result.data?.success && Array.isArray(result.data.data)) {
                let temp = wishListId.filter( item => item !== id )
                setWishListId(temp)
                // dispatch({
                //     type: 'SET_WISHLIST',
                //     payload: [...result.data.data]
                // })
                // let temp = result.data.data.map( w => w.productId._id )
                // setWishListId(temp)
                // console.setWishListId()
            }
        } else setShowToast(true)     
    }

    return (
        <>
            {
                showToast && <Toast message='Please login to continue!' setShowToast={setShowToast} /> 
            }
            <div className={`${col} col-6 col-sm-3 col-xs-6 px-2 mb-3`}>
            <Link  to={{ pathname: `/product/${product._id}`, state: product }}>
                <div className="card product-card card-static pb-3 border hoverBorder">
                    {
                        isSale && <span className="badge bg-danger badge-shadow0">Sale</span>
                    }

                    {
                        wishListId && wishListId.includes(product._id) 
                        ? (<button onClick={ e => deleteWishList(e, product._id) } className="opacity-0 btn-wishlist bg-light p-0 mt-0 btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title='' data-bs-original-title="Add to wishlist" aria-label="Add to wishlist">
                            <LoveFilled/>
                        </button>)
                        : (<button onClick={ e => handleWishList(e, product._id) } className="btn-wishlist bg-light btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title='' data-bs-original-title="Remove from wishlist" aria-label="Remove from wishlist">
                            <i className="ci-heart fs-lg" />  
                        </button>)
                    }

                    <span className='card-img-top d-block overflow-hidden' to={`/product/${product._id}`}>
                        <img
                            onLoad={loadingHandle}
                            className={`p-4 ${ !loaded && 'd-none'}`}
                            src={`${process.env.REACT_APP_API_FILES}/images/${product._id}/${product.featureImage}`}
                            alt={product.title}
                        />
                        <div className={`my-5 pt-5 d-flex justify-content-center ${ loaded && 'd-none' }`}><Loading /></div>
                    </span>
                    <div className="card-body pb-2 pt-0">
                        <span className="product-meta d-block fs-xs pb-1" href="#">{product.organization.productType.name}</span>
                        {/* <a className="product-meta d-block fs-xs pb-1" href="#">{product.postedBy.phone}</a> */}
                        <h3 className="product-title fs-sm text-truncatex trucate2line"><span to={`/product/${product._id}`}><b className='fs-md'>{product.title}</b></span></h3>
                        <div className="product-price"><span className="text-danger fw-bold">{ isSale ? currencyFormarter({ amount: product.pricing.price-product.pricing.discount}) : currencyFormarter({ amount: product.pricing.price })} </span>
                            { isSale && <del className="fs-sm text-muted"> {currencyFormarter({ amount: product.pricing.price})}</del>}
                        </div>
                    </div>
                    {
                        auth && auth.token && auth.user && auth.user._id === product.postedBy._id &&
                        <div className="product-floating-btn">
                            <button className="btn btn-primary btn-shadow btn-sm" to={`/edit-product/${product._id}`}><i className="ci-edit-alt fs-base ms-1" /></button>
                            <button onClick={ e => handleDelectProduct(e) } className="btn btn-primary btn-shadow btn-sm"><i className="ci-trash fs-base ms-1" /></button>
                        </div>
                    }
                </div>
                </Link>
            </div> 
        </>
    )
}

export default ProductCard
