import React, { useState, useEffect } from 'react'
import axios from 'axios'

function CategorySelect({ state={}, setState }) {

    // console.log(state);

    const [category, setCategory] = useState([])

    useEffect(() => {
        loadCategory()
    }, [])

    const loadCategory = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API}/main-categories`)
        setCategory([...data])
    }

    const handleChangeMainCat = (e) => {
        setState({ ...state, mainCategory: e.target.value})
        // console.table({id: e.target.value , value:e.target.options[e.target.selectedIndex].text});
    }

    return (
        <> 
            <div className="py-2 dropdown">
                <select onChange={ e => handleChangeMainCat(e) } className="form-select me-2" id="unp-category" required>
                    {/* <option disabled selected={ state && !state._id } value>Please choose one...</option> */}
                    <option disabled>Please choose one...</option>
                    {
                        category && category.map( (c, i) => (
                            <option
                                selected={ state && state._id && state.mainCategory === c._id}
                                value={c._id} key={i}
                                data-content={`<i class={${c.icon} me-2}></i>`}>
                                {c.name}
                            </option>)
                        )
                    }
                </select>
            </div>
        </>
    )
}

export default CategorySelect
