import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

function Logout() {

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        // 1. remove auth or user on redux state
        dispatch({
            type: 'LOGOUT',
            payload: null
        })

        // 2. remove auth or user from localStorage
        window.localStorage.removeItem('auth')

        // 3. redirect to Login page
        history.push('/login')
    }, [])

    return <></>
}

export default Logout
