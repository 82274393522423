import React from 'react'

function DiscountInput({ state, setState }) {
    return (
        <div className="col-sm-4 mb-3">
            <label className="form-label fw-bold" htmlFor="unp-extended-price">Discount</label>
            <div className="input-group"><span className="input-group-text"><i className="ci-dollar" /></span>
                <input
                    value={state.pricing.discount}
                    onChange={(e) => setState({ ...state, pricing: { ...state.pricing, discount: e.target.value } })}
                    className="form-control" type="text" id="unp-extended-price" autoComplete="off"
                />
            </div>
            {/* <div className="form-text">Typically 10x of the Standard license price.</div> */}
        </div>
    )
}

export default DiscountInput
