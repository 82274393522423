import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

function BrandHeader() {

    const [brands, setBrands] = useState([])

    useEffect(() => {
        loadBrand()
    }, [])

    const loadBrand = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API}/vendor`)
        setBrands(data)
        // console.log(brands);
    }

    return (
        <>
            <div className='bg-light rounded-3 border rounded h-100'>
                <div className="tab-content pt-3 px-3">
                    <div className="d-flex flex-wrap flex-md-nowrap px-2">
                        <div className="pt-4 pb-0 py-sm-4 px-3">
                            <div className="widget widget-links mb-3">
                                <h6 className="fs-base mb-4 fw-bold text-center0">Shop by Brand:</h6>
                                {/* <ul className="widget-list">
                                    {
                                        brands && brands.map( (s, i) =>
                                        <li className="widget-list-item pb-1" key={i}>
                                            <a className="widget-list-link" href="#">{s.name}</a>
                                        </li>)
                                    }
                                </ul> */}


<div className="row gx-3 gy-2">
  {/* <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/01.png" style={{width: '150px'}} alt="Brand" /></a></div> */}
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/01.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/02.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/03.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/04.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/05.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/06.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/07.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/08.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/09.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/10.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/11.png" style={{width: '150px'}} alt="Brand" /></a></div>
  <div className="col-md-3 col-sm-4 col-6"><a className="d-block border rounded-3 py-sm-2 hoverBorder" href="#"><img className="d-block mx-auto" src="img/shop/brands/12.png" style={{width: '150px'}} alt="Brand" /></a></div>
  
</div>


<div className='mt-2'>
<   a className="fs-sm" href="shop-grid-ls.html">View more<i className="ci-arrow-right fs-xs ms-1" /></a>
</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandHeader
