import React, { useEffect, useState }  from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Dashboard from '../Dashboard'
import { getWishList, removeWishList } from '../../Actions/WishList'
import { currencyFormarter } from '../../Hooks/CurrencyFormart'
import Loading from '../../Components/Loading'

function WishList({ history }) {

    const { auth, wishlist } = useSelector( state => ({...state}))
    // const dispatch = useDispatch()

    const [list, setList] = useState([])
    const [refreshPage, setRefreshPage] = useState(true)

    useEffect(() => {
        // if(!wishlist) getWishList(auth?.token).then( r => r.data?.success && setList(r.data.data))
        // else setList(wishlist)
        refreshPage && getWishList(auth?.token).then( r => {
            r.data?.success && setList(r.data.data)
            setRefreshPage(false)
        })
        
    }, [refreshPage])

    const deleteWishList = async (e, id) => {
        e.preventDefault()
        setRefreshPage(false)
        const result = await removeWishList(auth?.token, id)
        if (result.data?.success && Array.isArray(result.data.data)) {
            setRefreshPage(true)
        }        
    }

    return (
        <><Dashboard className='pe-xl-5' url={history.location.pathname} notifyCount={list.length}>
            <section className="col-lg-8 pt-lg-4 pb-4 mb-3 pe-xl-5">

                {/* Toolbar*/}
                <div className="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
                    <h6 className="fs-base mb-0">List of items you added to wishlist:</h6>
                    <Link className="btn btn-danger btn-sm" to="/logout"><i className="ci-sign-out me-2" />Logout</Link>
                </div>

                {/* Wishlist*/}
                {/* Item*/}
                {
                    auth?.token && list.length > 0
                    ? list.map( (w, index) => 

                        <div className="d-sm-flex justify-content-between mt-lg-4 mb-4 pb-3 pb-sm-2 border-bottom" key={index}>
                            <div className="d-block d-sm-flex align-items-start text-center text-sm-start">
                                <Link className="d-block flex-shrink-0 mx-auto me-sm-4" to={`/product/${w.productId._id}`} style={{width: '7.5rem'}}>
                                    <img src={`${process.env.REACT_APP_API_FILES}/images/${w.productId._id}/${w.productId.featureImage}`} alt="Product" />
                                </Link>
                            <div className="pt-2">
                                <h3 className="product-title fs-base mb-2"><Link to={`/product/${w.productId._id}`}>{w.productId.title}</Link></h3>
                                {/* <div className="fs-sm"><span className="text-muted me-2">Brand:</span>Tommy Hilfiger</div> */}
                                {/* <div className="fs-sm"><span className="text-muted me-2">Color:</span>Khaki</div> */}
                                <div className="fs-lg text-danger pt-2">
                                    { w.productId.pricing.discount > 0 ? currencyFormarter({ amount: w.productId.pricing.price-w.productId.pricing.discount}) : currencyFormarter({ amount: w.productId.pricing.price })}
                                    { w.productId.pricing.discount > 0 && <del className="fs-sm text-muted ms-2"> {currencyFormarter({ amount: w.productId.pricing.price})}</del>}
                                </div>
                            </div>
                            </div>
                            <div className="pt-2 ps-sm-3 mx-auto mx-sm-0 text-center">
                                <button onClick={ e => deleteWishList(e, w.productId._id) } className="btn btn-outline-danger btn-sm" type="button"><i className="ci-trash me-2" />Remove</button>
                            </div>   
                        </div>

                    )
                    : refreshPage ? <div className={`my-5 py-5 d-flex justify-content-center `}><Loading /></div>
                    :
                    <div className='text-center mb-5 py-5'>
                        <h3>Your wishlist is empty</h3>
                        Explore more and shortlist some items <br/><br/>
                        <Link className="btn btn-danger btn-sm" to="/">START SHOPPING</Link>
                    </div>
                }
 
            </section>
        </Dashboard></>
    )
}

export default WishList
