import { combineReducers } from 'redux'
import authReducer from './auth'
import catReducer from './category.js'
import wishlistReducer from './wishlist'

const rootReducer = combineReducers({
    auth: authReducer,
    category: catReducer,
    wishlist: wishlistReducer
})

export default rootReducer