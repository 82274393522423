import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router"

const PrivateRoute = ({ ...rest }) => {
    const { auth } = useSelector( state => ({ ...state}) )
    
    return (
        // auth && auth.token && auth.user ? <Route {...rest} /> : <Redirect to='/' />
        auth && auth.token && auth.user && <Route {...rest} />
    )
}

export default PrivateRoute