let categoryState

if (window.localStorage.getItem('category')) {
    // It help us to restore state when page is reload
    // Get data from local storage to redux state
    // and Then covert it from json to Javascript Object using JSON.parse()
    // categoryState = JSON.parse(window.localStorage.getItem('category'))
} else {
    categoryState = null
}

const catReducer = (state = categoryState, action) => {
    switch (action.type) {
        case 'SET':
            return [...action.payload]
        case 'REMOVE':
            return action.payload
        default:
            return state
    }
}

export default catReducer