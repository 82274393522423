import React, { useState, useEffect } from 'react'
import { getOrderSummary } from '../Actions/Order'
import { useSelector } from 'react-redux'
import Modal from '../Components/Modal'
import { currencyFormarter } from '../Hooks/CurrencyFormart'

function CheckOut({ data, showToggle, show }) {

    const { auth } = useSelector(state => ({...state}))
    const [tran, setTran] = useState({})
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        getOrder()
    }, [])

    const getOrder = async () => {
        const res = await getOrderSummary(auth?.token, data._id)
        res && setTran(res.data)
        setLoaded(true)
        console.log(tran)
    }

    return (
        <><Modal showToggle={showToggle} show={show} size='modal-xl'>
            { tran && tran.orderCheckout && loaded &&
            // <div className="container mb-5 pb-3">
                // <div className="bg-light shadow-lg rounded-3 overflow-hidden">
                    <div className="row">
                        {/* Content*/}
                        <section className="col-lg-7 pt-2 pt-lg-4 pb-4 mb-3">
                            <div className="pt-2 px-4 pe-lg-0 ps-xl-5">
                                {/* Title*/}
                                <h2 className="h6 border-bottom pb-3 mb-3 fw-bold">Order summary</h2>
                                {/* Billing detail*/}
                                
                                {/* Order preview on mobile (screens small than 991px)*/}
                                {/* <div className="widget mb-3 d-lg-none">
                                    <h2 className="widget-title">Order summary</h2>
                                    <div className="d-flex align-items-center pb-2 border-bottom"><a className="d-block flex-shrink-0 me-2" href="marketplace-single.html"><img className="rounded-1" src="img/marketplace/products/widget/01.jpg" width={64} alt="Product" /></a>
                                        <div className="ps-1">
                                            <h6 className="widget-product-title"><a href="marketplace-single.html">UI Isometric Devices Pack</a></h6>
                                            <div className="widget-product-meta"><span className="text-accent border-end pe-2 me-2">$23.<small>99</small></span><span className="fs-xs text-muted">Standard license</span></div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center py-2 border-bottom"><a className="d-block flex-shrink-0 me-2" href="marketplace-single.html"><img className="rounded-1" src="img/marketplace/products/widget/02.jpg" width={64} alt="Product" /></a>
                                        <div className="ps-1">
                                            <h6 className="widget-product-title"><a href="marketplace-single.html">Project Devices Showcase</a></h6>
                                            <div className="widget-product-meta"><span className="text-accent border-end pe-2 me-2">$18.<small>99</small></span><span className="fs-xs text-muted">Standard license</span></div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center py-2 border-bottom"><a className="d-block flex-shrink-0 me-2" href="marketplace-single.html"><img className="rounded-1" src="img/marketplace/products/widget/03.jpg" width={64} alt="Product" /></a>
                                        <div className="ps-1">
                                            <h6 className="widget-product-title"><a href="marketplace-single.html">Gravity Devices UI Mockup</a></h6>
                                            <div className="widget-product-meta"><span className="text-accent border-end pe-2 me-2">$15.<small>99</small></span><span className="fs-xs text-muted">Standard license</span></div>
                                        </div>
                                    </div>
                                    <ul className="list-unstyled fs-sm py-3">
                                        <li className="d-flex justify-content-between align-items-center"><span className="me-2">Subtotal:</span><span className="text-end">$58.<small>97</small></span></li>
                                        <li className="d-flex justify-content-between align-items-center"><span className="me-2">Taxes:</span><span className="text-end">$10.<small>45</small></span></li>
                                        <li className="d-flex justify-content-between align-items-center fs-base"><span className="me-2">Total:</span><span className="text-end">$69.<small>42</small></span></li>
                                    </ul>
                                </div> */}
                                {/* Payment methods accordion*/}
                                    <div className="d-flex align-items-center pb-2 border-bottom"><a className="d-block flex-shrink-0 me-2"><img className="rounded-1" src={ `${process.env.REACT_APP_API_FILES}/images/${data._id}/${data.featureImage}` } width={64} alt="Product" /></a>
                                        <div className="ps-1">
                                            <h6 className="widget-product-title">{ data.title}</h6>
                                            <div className="widget-product-meta"><span className="text-accent border-end pe-2 me-2">{currencyFormarter({amount: tran.product.pricing.price})}</span><span className="fs-xs text-muted">1 item</span></div>
                                        </div>
                                    </div>
                                    <ul className="list-unstyled fs-sm pt-3 pb-2 border-bottom">
                                        <li className="d-flex justify-content-between align-items-center"><span className="me-2">Subtotal:</span><span className="text-end text-accent">{currencyFormarter({amount: tran?.product.pricing.price})}</span></li>
                                        <li className="d-flex justify-content-between align-items-center"><span className="me-2">Discount:</span><span className="text-end text-accent">{currencyFormarter({amount: tran?.product.pricing.discount})}</span></li>
                                    </ul>
                                    <h3 className="fw-bold text-center my-4 text-danger">{currencyFormarter({amount: tran.orderCheckout.amount})}</h3>

                                
                            </div>
                        </section>
                        {/* Sidebar*/}
                        {/* Order preview on desktop (screens larger than 991px)*/}
                        <aside className="col-lg-5 xd-none xd-lg-block ps-xl-5">
                            <hr className="d-lg-none" />
                            <div className="ps-4 pt-4 h-100 ms-auto border-start">
                                <div className="widget px-lg-2 py-2 mb-3">
                                    
                                    <div className="row pb-40 gx-40 gy-30">

                                    <h2 className="widget-title fw-bold">Billing details :</h2>
                                    <form method='POST' action='https://sandbox.payway.com.kh/sandbox/api/564/'>
                                        <div className="col-sm-12" hidden>
                                            <label className="form-label" htmlFor="mc-fn">Name <span className="text-danger">*</span></label>
                                            <input required className="form-control" type="text" defaultValue={ tran.orderCheckout.firstname } name='firstname'/>
                                        </div>
                                        <div className="col-sm-12" hidden>
                                            <label className="form-label" htmlFor="mc-ln">Last name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" defaultValue={tran.orderCheckout.lastname} name='lastname'/>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label" htmlFor="mc-email">Email <span className="text-danger">*</span></label>
                                            <input required className="form-control" type="email" defaultValue={ tran.orderCheckout.email } name='email'/>
                                        </div>
                                        <div className="col-sm-12 mb-4" hidden>
                                            <label className="form-label" htmlFor="mc-company">Phone</label>
                                            <input className="form-control" type="text" defaultValue={ tran.orderCheckout.phone } name='phone' />
                                        </div>
                                        <div className="col-sm-6" hidden>
                                            <label className="form-label" htmlFor="mc-company">Amount</label>
                                            <input className="form-control" type="text" defaultValue={tran.orderCheckout.amount} name='amount' />
                                        </div>
                                        <div className="col-sm-6" hidden>
                                            {/* <label className="form-label" htmlFor="mc-company">Tran Id</label> */}
                                            <input className="form-control" type="text" defaultValue={tran.orderCheckout.tran_id} name='tran_id' hidden/>
                                        </div>
                                        <div className="col-sm-6" hidden>
                                            {/* <label className="form-label" htmlFor="mc-company">Hash</label> */}
                                            <input className="form-control" type="text" defaultValue={tran.orderCheckout.hash} name='hash'/>
                                        </div>
                                        {/* <input hidden name='return_params' defaultValue={JSON.stringify(tran.orderCheckout)}/> */}
                                        {/* <input hidden name='return_url' defaultValue={btoa(process.env.REACT_APP_ABA_PUSH_BACK_URL)}/> */}
                                        <div className="col-12 mt-3">
                                            <label className="form-label mb-3" htmlFor="fd-comments"><span className="badge bg-info fs-xs me-2">Note</span>Additional comments</label>
                                            <textarea className="form-control" rows={5} id="fd-comments" defaultValue={""} />
                                        </div>


                                    {/* List group with radios */}
                                    <h2 className="widget-title fw-bold mt-5 pt-4 border-top"><span className="badge bg-danger">Demo</span> Payment methods :</h2>
                                    <div className="list-group mb-5 mt-4">
                                        <form>
                                        <label className="list-group-item">
                                            <input className="form-check-input me-2" type="radio" name="lg-radio" defaultChecked defaultValue />
                                            <span>ABA Payway</span> <img className='ps-2 mt-n1 img-fluid' width='150px' src={ require('../Assets/payment.png').default } />
                                        </label>
                                        <label className="list-group-item text-muted">
                                            <input className="form-check-input me-2" type="radio" name="lg-radio" disabled defaultValue />
                                            <span>Wing</span> <img className='ps-2 mt-n1 img-fluid' width='43px' src={ require('../Assets/wing.png').default } />
                                        </label>
                                        <label className="list-group-item text-muted" title='I will pay with cash to the courier on delivery.'>
                                            <input className="form-check-input me-2" type="radio" name="lg-radio" defaultValue disabled/>
                                            <span>Cash on delivery</span>
                                            <i className="ci-wallet text-muted fs-lg align-middle mt-n1 ms-2"></i>
                                        </label>
                                        </form>
                                    </div>
  
                                    <button className='btn btn-block w-100 btn-danger' stylev={{ backgroundColor: '#055d7c'}} type='submit'>Process Payment</button>
                                    </form>
                                </div>

                                <p className='fs-sm mt-3 text-danger text-center'>*** Warning: Now we're only on testing mode. <br/>We doesn't accept any orders. We'll launch soon...</p>                                  
                                
                                </div>
                            </div>
                        </aside>
                    </div>
                // </div>
            // </div>
        }
        </Modal>
        </>
    )
}

export default CheckOut
