import axios from 'axios'

export const allProducts = async () => await axios.get(`${process.env.REACT_APP_API}/products`, { timeout: process.env.REACT_APP_AXIO_TIMEOUT })

export const singleProduct = async (idProduct) => await axios.get(`${process.env.REACT_APP_API}/product/${idProduct}`)

export const singleEditProduct = async (token, idProduct) => await axios.get(`${process.env.REACT_APP_API}/product-edit/${idProduct}`, {
    headers: { Authorization: `bearer ${token}` },
    timeout: process.env.REACT_APP_AXIO_TIMEOUT
})

export const createProduct = async (token, data) => await axios.post(`${process.env.REACT_APP_API}/product`, data, {
    headers: {
        Authorization: `bearer ${token}`,
        // contentType: 'multipart/form-data'
        contentType: 'application/json'
        
    },
    timeout: process.env.REACT_APP_AXIO_TIMEOUT
})

export const updateProduct = async (token, id, data) => await axios.put(`${process.env.REACT_APP_API}/product/${id}`, data, {
    headers: {
        Authorization: `bearer ${token}`,
        contentType: 'application/json'     
    },
    timeout: process.env.REACT_APP_AXIO_TIMEOUT
})

export const deleteProduct = async (token, idProduct) => await axios.delete(`${process.env.REACT_APP_API}/product/${idProduct}`, {
    headers: { Authorization: `bearer ${token}` },
    timeout: process.env.REACT_APP_AXIO_TIMEOUT
})

export const viewCount = async (id) => await axios.get(`${process.env.REACT_APP_API}/product-view/${id}`, { timeout: process.env.REACT_APP_AXIO_TIMEOUT })