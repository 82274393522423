import React from 'react'

function ContinueSellOutStockCheckbox({ state, setState }) {
    return (
        <div className="form-check">
            <input
                onChange={(e) => setState({ ...state, inventory: { ...state.inventory, sellOnOutStock: e.target.checked } })}
                // defaultChecked={state.inventory.sellOnOutStock}
                checked={state.inventory.sellOnOutStock}
                className="form-check-input" type="checkbox" id="ex-check-4" />
            <label className="form-check-label" htmlFor="ex-check-4">Continue selling when out of stock</label>
        </div>
    )
}

export default ContinueSellOutStockCheckbox
