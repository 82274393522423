import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Header from './Layouts/Header'
import PrivateRoute from './Components/PrivateRoute'
import SuperAdmin from './Hooks/SuperAdmin';

// Page 
import Home from './Pages/Home'
import AccountRegisterLogin from './Pages/AccountRegisterLogin'
import Login from './Pages/Login';
import ProductDetail from './Pages/ProductDetail';
import Footer from './Layouts/Footer';
import Logout from './Pages/Logout';

// Dashboard
import Profile from './Pages/SubDashboard/Profile';
import AddProduct from './Pages/SubDashboard/AddProduct';
import EditProduct from './Pages/SubDashboard/EditProduct';
import PaymentSuccess from './Pages/PaymentSuccess';
import WishList from './Pages/SubDashboard/WishList';
import Shop from './Pages/Shop';


function App() {
    return (
        <BrowserRouter>
            <Header />
            
                <div className='bg-secondary'>
                    <section className="pt-4 container">
                        <div className='row'><Switch>
                            <Route exact path='/'><Home/></Route>
                            <Route exact path='/shop' component={Shop}/>
                            <Route exact path='/product/:id' component={ProductDetail} />
                            <Route exact path='/register' component={AccountRegisterLogin} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/logout' component={Logout} />

                            <PrivateRoute exact path='/profile' component={Profile} />
                            <PrivateRoute exact path='/wishlist' component={WishList} />
                            <PrivateRoute exact path='/payment-success' component={PaymentSuccess} />

                            <SuperAdmin exact path='/add-product' component={AddProduct} />
                            <SuperAdmin exact path='/edit-product/:id' component={EditProduct} /></Switch>
                        </div>
                    </section>
                </div>
            
            <Footer />
        </BrowserRouter>
    )
}

export default App;
