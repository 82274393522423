import React, { useState, useEffect } from 'react'
import ImageUploadItem from './ImageUploadItem'

function ImageInput({ state, setState }) {

    const [imgPreview, setImgPreview] = useState({
        main: '',
        media: []
    })

    useEffect(() => {
        state?._id && setImgPreview( imgPreview => ({
            ...imgPreview,
            main: `${process.env.REACT_APP_API}/product/image/${state._id}`,
            media: [...state.media.map( (m, i) => m != null && `${process.env.REACT_APP_API}/product/media/${state._id}&${i}`)]
        }))
    }, [state._id])

    const handleChangeImage = (e) => {

        if (e.target.files[0].size > 1e6) {
            window.alert("Please upload a file smaller than 1MB");
            return false;
        }
        // set Preview image
        setImgPreview({ ...imgPreview, main: URL.createObjectURL(e.target.files[0])})

        // set image to state
        setState({ ...state, featureImage: e.target.files[0] })
        // console.log(e.target.files[0])
    }

    return (

        <div className="row mb-4 mt-2 g-2 overflow-hidden" style={{ height: "200px !important" }}>
            {/* <pre>{ JSON.stringify(imgPreview, null, 4)}</pre> */}
            <div className="col-lg-4">
                <div className="position-relativea h-100 border rounded align-self-center bg-faded-dark pointer file-drop-area overflow-hidden" style={{ height: "200px !important" }}>
                    {  imgPreview && <img src={imgPreview.main} className='position-absolute top-0 start-0 img img-fluid rounded' alt='' style={{minHeight: '200px', width: 'auto'}}/> }
                    <div className='align-self-center h-100 d-flex align-items-center'>
                         
                             <h6 className='text-center w-100 mb-0 text-muted'>
                                <i className='ci-image h4 text-muted'></i>
                                <br/>
                                <b>Feature Image</b><br/>
                                <small className='fs-xs'>Click to choose file</small>
                                <input
                                    onChange={handleChangeImage}
                                    type="file" accept='image/*' name='image'
                                    className="file-drop-input pointer"
                                    required
                                />
                            </h6>
                        
                    </div>
                    
                </div>
            </div>
            {
                [...Array(4)].map((e, i) =>
                    <div className="col-lg-2" key={`d${i}`}>
                        <div className="h-100">
                            <ImageUploadItem keyItems={i} padding={'pb-1'} state={state} setState={setState} imgPreview={imgPreview} setImgPreview={setImgPreview}/>
                            <ImageUploadItem keyItems={ 4+i } padding={'pt-1'} state={state} setState={setState} imgPreview={imgPreview} setImgPreview={setImgPreview}/>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ImageInput
