import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

function VendorInput({ state, setState }) {

    const { auth } = useSelector(state => ({ ...state }))
    const { token } = auth

    const [initialState, setInitialState] = useState([])

    // Load main category from server
    useEffect(() => {
        // console.log(state.brand);
        loadVendor()
    }, [ state._id && state.brand])

    const loadVendor = async (id) => {
        let { data } = await axios.get(`${process.env.REACT_APP_API}/vendor/`)
        setInitialState(data)
        setDisplayVendor(data)
        // state && !state._id && setState({ ...state, organization: { ...state.organization, vendor: '' } })
        state && state._id ? setCurrentValue(state.brand) : setCurrentValue('')
    }

    const [displayVendor, setDisplayVendor] = useState([])

    const [existVendor, setExistVendor] = useState(false)

    const [currentValue, setCurrentValue] = useState('')

    const addVendorMarkUp = (
        <li className="list-group-item-action dropdown-item pointer" onClick={ e => addNewVendor(currentValue) }>
            <i className="ci-add-circle text-primary me-1"></i>
            <b className='text-primary'>Add</b> { currentValue }
        </li>
    )

    // Action on user choose a TYPE
    const handleSelectItem = (e) => {
        setCurrentValue(e.target.outerText)
        setExistVendor(true)
        setState({ ...state, organization: { ...state.organization, vendor: e.target.attributes['data-value'].value } })
    }

    const handleChangeTyping = (e) => {
        // Get value on typing
        const val = e.target.value

        // filter on change
        // setDisplayVendor(initialState.filter(t => t.toLowerCase().startsWith(val.toLowerCase())))
        let temp = []
        initialState && initialState.map( c => c.name.toLowerCase().startsWith(val.toLowerCase()) && temp.push(c))
        setDisplayVendor([...temp])

        setCurrentValue(val)

        temp.map( c => c.name.toLowerCase() == val.toLowerCase() ? setExistVendor(true) : setExistVendor(false) )
        // temp.length == 0 ? setExistVendor(true) : setExistVendor(false)

        // set value to state
        // setState({ ...state, organization: { ...state.organization, vendor: val } })

        // covert all types to lowercase
        // const typeLowcase = initialState.map(t => t.toLowerCase())

        // if not have on db. Add new
        // typeLowcase.includes(val.toLowerCase()) ? setExistVendor(true) : setExistVendor(false)
    }

    // add waring if user type and not select or add new
    // const typeNotValid = (e) => {
    //     if (!initialState.includes(e.target.value)) {
    //         setState({ ...state, organization: { ...state.organization, vendor: '' } })
    //         setCurrentValue(''.slice(0,1))
    //     } 
    // }

    // request to server to add new sub-category if not exist
    const addNewVendor = async (val) => {
        console.log(token)

        let res = await axios.post(`${process.env.REACT_APP_API}/vendor`, { name: val }, {
            headers: { Authorization: `bearer ${token}` },
            timeout: 3000
        })
        console.log(res);
        res && setInitialState(res.data)
        res && setDisplayVendor(res.data)
        res.data.map( d => d.name === val && setState({ ...state, organization: { ...state.organization, vendor: d._id } }))
        setExistVendor(true)
    }

    return (
        <div className="col-sm-6 mb-0 py-2 0btn-group">
            {/* <pre>{JSON.stringify(existVendor)}</pre> */}
            <div className="mb-1 pb-1 dropdown" aria-haspopup="true" aria-expanded="false">
                <label className="form-label fw-bold" htmlFor="unp-product-name">Brand or Vendor</label>
                <input
                    value={currentValue}
                    onChange={ e => handleChangeTyping(e) }
                    // onBlur={ e => typeNotValid(e) }
                    className="form-control dropdown-toggle" placeholder='eg. Apple. no brand? choose Other'
                    data-bs-toggle="dropdown" type="text" id="unp-product-name" autoComplete="off"
                    required
                />
                <div className="dropdown-menu w-100 border border-primary mt-2">
                    {!existVendor && currentValue && addVendorMarkUp}
                    {
                        displayVendor.map((t, i) =>
                            <li
                                onClick={ e => handleSelectItem(e) }
                                key={i}
                                data-value={t._id}
                                className="list-group-item-action dropdown-item pointer"
                                key={i}>{t.name}
                            </li>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default VendorInput
