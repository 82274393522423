import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { numberValidate, limitLength } from '../Hooks/FormValidation'
import { requestLogin } from '../Actions/Auth'
import { scrollToTop } from '../Hooks/ScrollToTop'

function Login(props) {

    const history = useHistory()

    const dispatch = useDispatch()

    const { auth } = useSelector( state => ({...state}))
    
    useEffect(() => {
        // console.log('UseEffect', props)
        scrollToTop()
        if(auth?.token && auth?.user) return history.push('/dashboard')
    }, [])

    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(true)

    const [err, setErr] = useState('')
    const [errCount, setErrCount] = useState(0)
    const [showPwd, setShowPwd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [block, setBlock] = useState(false)

    const errMarkUp = <div className="invalid-feedback d-block text-center">{err}</div>

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleLogin = async (e) => {
        // console.log('pressed login btn');
        setErr('')
        setLoading(true)
        try {
            const res = await requestLogin({phone:phone.replace(/^0+/, '+855'), password})
            // console.log(res);
            if (res?.data?.success) {
                // console.log('SAVE USER RES IN REDUX & LOCAL STORAGE THEN REDIRECT ==>')
                    // console.log(data)
    
                    // Save res.data to Local Storage
                    remember && window.localStorage.setItem('auth', JSON.stringify({ token: res.data.token, user: res.data.user }))
    
                    // Save res.data to redux
                    dispatch({
                        type: 'LOGGED_IN',
                        payload: { token: res.data.token, user: res.data.user }
                    })
    
                    // When success redirect to Homepage
                    return history.push('/')
            } else if (res?.data?.block) {
                setBlock(res.data.block)
                let { hour, minute, second } = res.data.eta
                setErr(
                    <span>Many attempt using wrong password!
                        <br/>You must wait
                        <b> {hour > 0 && hour} { hour > 1 ? 'hours, ' : hour > 0 ? 'hour, ' : ''}
                            {minute > 0 && minute} { minute > 1 ? 'minutes, ' : minute > 0 ? 'minute, ' : ''}
                            {second > 0 && second} { second > 1 ? 'seconds ' : second > 0 ? 'second ' : ''}
                        </b>
                        before trying again.
                    </span>
                )
            } else if ( res?.data?.attempt ) {
                setLoading(false)
                setErrCount(res.data.counts)
                setErr(`Wrong password (${res.data.counts} ${(res.data.counts<1)?'time':'times'}). Try again!`)
                // setErr(res.data.message)
            } else if (!res?.data?.exist ) {
                setLoading(false)
                setErr(<span>{res.data.message}</span>)
            }
            setLoading(false)

        } catch (error) {
            console.log(error);
        }
    }

    const phoneChange = (e) => {
        limitLength(e, 10)
        setPhone(e.target.value)
    }

    return (
        <>
            {/* Body of account signin */}
            <div className="container py-4 py-lg-5 my-2">
                <div className="row">
                    {/* <pre>{JSON.stringify({phone, password, remember}, null, 4)}</pre> */}
                    <div className="col-md-6 offset-md-3">
                        <div className="card border-0 p-4 pt-2 pb-2 shadow">
                            <div className="card-body">

                                <h3 className="h3 pt-3 text-center fw-bold">
                                    Welcome !
                                </h3>
                                <p className='fs-md text-center mb-3 text-muted'>Login to your account now</p>
                                <form className="needs-validation" noValidate onSubmit={handleSubmit}>

                                <div className="input-group mb-3">
                                        <i className="ci-mobile position-absolute top-50 translate-middle-y text-muted fs-base ms-3" />
                                        {/* <label className="form-label" htmlFor={errId}>{title}</label> */}
                                        <input value={phone} onKeyDown={numberValidate} onChange={phoneChange} className="form-control rounded-start" name='phone' type='number' placeholder='093575933' required id='reg-phone' />
                                        {/* <div className="invalid-feedback">{errText}</div> */}
                                    </div>

                                    <div className="input-group mb-3">
                                        <i className="ci-locked position-absolute top-50 translate-middle-y text-muted fs-base ms-3" />
                                        <div className="password-toggle w-100">
                                            {/* <label className="form-label" htmlFor="reg-password">Password</label> */}
                                            <input value={password} onChange={(e) => setPassword(e.target.value)} minLength="6" name='password' className="form-control" type={showPwd ? 'text' : 'password'} placeholder="Password" required />
                                            <div className="invalid-feedback">Please enter password!</div>
                                            <label className="password-toggle-btn" aria-label="Show/hide password">
                                                <input onChange={() => setShowPwd(!showPwd)} defaultChecked={showPwd} className="password-toggle-check" type="checkbox" id="reg-password" required /><span className="password-toggle-indicator" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-between mt-3">
                                        <div className="form-check">
                                            <input onChange={ (e) => setRemember(e.target.checked) } className="form-check-input" type="checkbox" defaultChecked={remember} id="remember_me" />
                                            <label className="form-check-label" htmlFor="remember_me">Remember me</label>
                                        </div><a className="nav-link-inline fs-sm" href="#">Forgot password?</a>
                                    </div>


                                    <hr />
                                    <div className="text-end pt-4 pb-4">
                                        <button onClick={handleLogin} disabled={!(phone.length >= 9 && password.length >= 6) || loading || (errCount >= 5) || block} className="btn btn-primary d-block w-100" type="submit">
                                            { loading 
                                            ? <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</>
                                            : <><i className="ci-locked me-2 ms-n21" />Login</> }
                                        </button>

                                    </div>
                                    { errMarkUp }

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <p className='fs-sm text-center mb-2'>New to Daklouk.com?</p>
            <div className='d-flex justify-content-center mb-4'>
            <Link to='/register' className='btn btn-outline-primary text-center'>Create account</Link>
            </div>
        </>
    )
}

export default Login
