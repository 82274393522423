let userState

if (window.localStorage.getItem('auth')) {
    // It help us to restore state when page is reload
    // Get data from local storage to redux state
    // and Then covert it from json to Javascript Object using JSON.parse()
    userState = JSON.parse(window.localStorage.getItem('auth'))
} else {
    userState = null
}

const authReducer = (state = userState, action) => {
    switch (action.type) {
        case 'LOGGED_IN':
            return { ...state, ...action.payload }
        case 'LOGOUT':
            return action.payload
        default:
            return state
    }
}

export default authReducer