import React from 'react'

function Slider() {
    return (
        <>
            <div className="tns-inner mb-4" id="tns1-iw"><div className="tns-carousel-inner tns-slider tns-gallery tns-subpixel tns-calc tns-horizontal" data-carousel-options="{&quot;items&quot;: 1, &quot;mode&quot;: &quot;gallery&quot;, &quot;nav&quot;: false, &quot;responsive&quot;: {&quot;0&quot;: {&quot;nav&quot;: true, &quot;controls&quot;: false}, &quot;576&quot;: {&quot;nav&quot;: false, &quot;controls&quot;: true}}}" id="tns1">
                {/* Slide 1*/}
                <div className="tns-item tns-fadeIn tns-slide-active" id="tns1-item0" style={{ left: '0%' }}>
                    <div className="rounded-3 px-md-5 text-center text-xl-start" style={{ backgroundColor: '#efe5fe' }}>
                        <div className="d-xl-flex justify-content-between align-items-center px-4 px-sm-5 mx-auto" style={{ xmaxWidth: '1226px' }}>
                            <div className="py-5 me-xl-4 mx-auto mx-xl-0" style={{ maxWidthx: '490px' }}>
                                <h2 className="h1 display-5 fw-bold">iPhone 12 & iPhone 12 mini</h2>
                                {/* <p className="text-light pb-4">get a new colour!</p> */}
                                <h5 className="tpb-3 fx-light">Get a new colour!</h5>
                                <div className="d-flex flex-wrap justify-content-center justify-content-xl-start">
                                    <a className="btn-market btn-apple me-2 mb-2 pb-2" href="#" role="button">
                                        <span className="btn-market-title">Buy Now</span>
                                    </a>
                                    </div>
                            </div>
                            <div><img src={ require(`../Assets/apple_iphone_12.jpg`).default } alt="Image" /></div>
                        </div>
                    </div>
                </div>
                {/* Slide 2*/}
                <div className="tns-item tns-normal" id="tns1-item1" aria-hidden="true" tabIndex={-1}>
                    <div className="rounded-3 px-md-5 text-center text-xl-start" style={{ backgroundColor: '#1a6fb0' }}>
                        <div className="d-xl-flex justify-content-between align-items-center px-4 px-sm-5 mx-auto" style={{ maxWidth: '1226px' }}>
                            <div className="py-5 me-xl-4 mx-auto mx-xl-0" style={{ maxWidth: '490px' }}>
                                <h2 className="h1 text-light">24/7 Delivery Service</h2>
                                <p className="text-light pb-4">Order any goods from our store online and we deliver them to your door at a time convenient for you.</p>
                                <h5 className="text-light pb-3">On the go? Try our mobile app</h5>
                                <div className="d-flex flex-wrap justify-content-center justify-content-xl-start"><a className="btn-market btn-apple me-2 mb-2" href="#" role="button"><span className="btn-market-subtitle">Download on the</span><span className="btn-market-title">App Store</span></a><a className="btn-market btn-google mb-2" href="#" role="button"><span className="btn-market-subtitle">Download on the</span><span className="btn-market-title">Google Play</span></a></div>
                            </div>
                            <div><img src="img/grocery/slider/slide02.jpg" alt="Image" /></div>
                        </div>
                    </div>
                </div>
                <div className="tns-item tns-normal tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
                    <div className="rounded-3 px-md-5 text-center text-xl-start" style={{ backgroundColor: '#59c879' }}>
                        <div className="d-xl-flex justify-content-between align-items-center px-4 px-sm-5 mx-auto" style={{ maxWidth: '1226px' }}>
                            <div className="py-5 me-xl-4 mx-auto mx-xl-0" style={{ maxWidth: '490px' }}>
                                <h2 className="h1 text-light">Fresh Foods a Click Away</h2>
                                <p className="text-light pb-4">Order any goods from our store online and we deliver them to your door at a time convenient for you.</p>
                                <h5 className="text-light pb-3">On the go? Try our mobile app</h5>
                                <div className="d-flex flex-wrap justify-content-center justify-content-xl-start"><a className="btn-market btn-apple me-2 mb-2" href="#" role="button"><span className="btn-market-subtitle">Download on the</span><span className="btn-market-title">App Store</span></a><a className="btn-market btn-google mb-2" href="#" role="button"><span className="btn-market-subtitle">Download on the</span><span className="btn-market-title">Google Play</span></a></div>
                            </div>
                            <div><img src="img/grocery/slider/slide01.jpg" alt="Image" /></div>
                        </div>
                    </div>
                </div><div className="tns-item tns-normal tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
                    <div className="rounded-3 px-md-5 text-center text-xl-start" style={{ backgroundColor: '#1a6fb0' }}>
                        <div className="d-xl-flex justify-content-between align-items-center px-4 px-sm-5 mx-auto" style={{ maxWidth: '1226px' }}>
                            <div className="py-5 me-xl-4 mx-auto mx-xl-0" style={{ maxWidth: '490px' }}>
                                <h2 className="h1 text-light">24/7 Delivery Service</h2>
                                <p className="text-light pb-4">Order any goods from our store online and we deliver them to your door at a time convenient for you.</p>
                                <h5 className="text-light pb-3">On the go? Try our mobile app</h5>
                                <div className="d-flex flex-wrap justify-content-center justify-content-xl-start"><a className="btn-market btn-apple me-2 mb-2" href="#" role="button"><span className="btn-market-subtitle">Download on the</span><span className="btn-market-title">App Store</span></a><a className="btn-market btn-google mb-2" href="#" role="button"><span className="btn-market-subtitle">Download on the</span><span className="btn-market-title">Google Play</span></a></div>
                            </div>
                            <div><img src="img/grocery/slider/slide02.jpg" alt="Image" /></div>
                        </div>
                    </div>
                </div></div></div>

        </>
    )
}

export default Slider
