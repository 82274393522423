import React from 'react'
import Dashboard from '../Dashboard'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function Profile({ history }) {

    const { auth } = useSelector(state => ({...state}))
    const user = auth?.user

    return (
        <>
            { auth && user &&
            <Dashboard className='pe-xl-5' url={history.location.pathname}>
            <section className="col-lg-8 pt-lg-4 pb-4 mb-3 pe-xl-5">
                {/* Toolbar*/}
                <div className="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
                    <h6 className="fs-base mb-0">Update you profile details below:</h6>
                    <Link className="btn btn-primary btn-sm" to="/logout"><i className="ci-sign-out me-2" />Logout</Link>
                </div>
                {/* Profile form*/}
                <form>
                    {/* <div className="bg-secondary rounded-3 p-4 mb-4">
                        <div className="d-flex align-items-center"><img className="rounded" src={ require('../../Assets/profile-placeholder.png').default } width={90} alt="Susan Gardner" />
                            <div className="ps-3">
                                <button className="btn btn-light btn-shadow btn-sm mb-2" type="button"><i className="ci-loading me-2" />Change avatar</button>
                                <div className="p mb-0 fs-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row gx-4 gy-3">
                        <div className="col-sm-6">
                            <label className="form-label" htmlFor="account-fn">Name</label>
                            <input className="form-control" type="text" id="account-fn" name='firstname' defaultValue={user.name} required/>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label" htmlFor="account-ln">Gender</label>
                            <select className="form-select  form-select">
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other" selected>Other</option>
                            </select>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label" htmlFor="account-email">Email Address</label>
                            <input className="form-control" type="email" id="account-email" name='email' defaultValue={user.email} required />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label" htmlFor="account-phone">Phone Number</label>
                            <input className="form-control" type="text" id="account-phone" name='phone' defaultValue={user.phone} required disabled/>
                        </div>
                        {/* <div className="col-sm-6">
                            <label className="form-label" htmlFor="account-pass">New Password</label>
                            <div className="password-toggle">
                                <input className="form-control" type="password" id="account-pass" />
                                <label className="password-toggle-btn" aria-label="Show/hide password">
                                    <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator" />
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label" htmlFor="account-confirm-pass">Confirm Password</label>
                            <div className="password-toggle">
                                <input className="form-control" type="password" id="account-confirm-pass" />
                                <label className="password-toggle-btn" aria-label="Show/hide password">
                                    <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator" />
                                </label>
                            </div>
                        </div> */}
                        <div className="col-12">
                            <hr className="mt-2 mb-3" />
                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                <button className="btn btn-primary mt-3 mt-sm-0" type="button">Update profile</button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            </Dashboard>
            }
        </>
    )
}

export default Profile
