import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

function TypeInput({ state, setState }) {

    const { auth } = useSelector(sta => ({ ...sta }))
    const { token } = auth

    // let initialState1 = ['Headset', 'Charging', 'Power Bank', 'Case']
    const [initialState, setInitialState] = useState([])

    // Load main category from server
    useEffect(() => {
        // state && state._id && setCurrentValue(state.organization.productType.name)
        state.mainCategory && loadSubCategory(state.mainCategory)
    }, [state.mainCategory])

    const loadSubCategory = async (id) => {
        let { data } = await axios.get(`${process.env.REACT_APP_API}/sub-categories/${id}`)
        setInitialState(data)
        setDisplayProductType(data)
        state && !state._id && setState({ ...state, organization: { ...state.organization, productType: '' } })
        state && state._id ? setCurrentValue(state.subCat) : setCurrentValue('')
    }

    const [currentValue, setCurrentValue] = useState('')


    // Display with filter while user type
    const [displayProductType, setDisplayProductType] = useState([])

    // to handle active Add new li
    const [existType, setExistType] = useState(false)

    // Action on user choose a TYPE
    const handleSelectItem = (e) => {
        // console.log(e.target.attributes['data-value'].value);
        // setState({ ...state, organization: { ...state.organization, productType: e.target.outerText e.target.attributes['data-value'].value } } })
        setCurrentValue(e.target.outerText)
        setExistType(true)
        setState({ ...state, organization: { ...state.organization, productType: e.target.attributes['data-value'].value } })
    }

    const handleChangeTyping = (e) => {
        // Get value on typing
        const val = e.target.value

        // filter on change
        let temp = []
        initialState.map( c => c.name.toLowerCase().startsWith(val.toLowerCase()) && temp.push(c))
        // setDisplayProductType(initialState.filter(t => t.toLowerCase().startsWith(val.toLowerCase())))
        setDisplayProductType([...temp])

        // set value to state
        // setState({ ...state, organization: { ...state.organization, productType: val } })
        setCurrentValue(val)

        temp.map( c => c.name.toLowerCase() === val.toLowerCase() ? setExistType(true) : setExistType(false) )

        // covert all types to lowercase
        // const typeLowcase = initialState.map(t => t.toLowerCase())
        // const typeLowcase = temp.map(t => t.toLowerCase())

        // if not have on db. Add new
        // typeLowcase.includes(val.toLowerCase()) ? setExistType(true) : setExistType(false)
    }

    // add waring if user type and not select or add new
    // const typeNotValid = (e) => {
    //     if (!initialState.includes(e.target.value)) {
    //         setState({ ...state, organization: { ...state.organization, productType: '' } })
    //         setCurrentValue(''.slice(0,1))
    //     } 
    // }

    // request to server to add new sub-category if not exist
    const addNewType = async (val) => {
        console.log(token)

        let res = await axios.post(`${process.env.REACT_APP_API}/sub-category`, { name: val, idMainCat: state.mainCategory }, {
            headers: { Authorization: `bearer ${token}` },
            timeout: 3000
        })
        console.log(res);
        res && setInitialState(res.data)
        res && setDisplayProductType(res.data)
        res.data.map( d => d.name === val && setState({ ...state, organization: { ...state.organization, productType: d._id } }))
        setExistType(true)
    }

    const addTypeMarkUp = (
        <li className="list-group-item-action dropdown-item pointer" onClick={ () => addNewType(currentValue) }>
            <i className="ci-add-circle text-primary me-1"></i>
            <b className='text-primary'>Add</b> { currentValue }
        </li>
    )

    return (
        <div className="col-sm-6 mb-0 pt-2">
            <div className="mb-1 pb-1 dropdown" aria-haspopup="true" aria-expanded="false">
                <label className="form-label fw-bold" htmlFor="unp-product-name">Product type</label>
                <input
                    onChange={ e => handleChangeTyping(e) }
                    // onBlur={ e => typeNotValid(e) }
                    className="form-control dropdown-toggle was-validated" placeholder='Please choose one or Add new'
                    value={currentValue}
                    data-bs-toggle="dropdown" type="text" id="unp-product-name" autoComplete="off"
                    required
                />
                <div className="dropdown-menu w-100 border border-primary mt-2">
                    {!existType && currentValue && addTypeMarkUp}
                    {
                        displayProductType.map((t, i) =>
                            <li
                                onClick={e => handleSelectItem(e)}
                                data-value={t._id}
                                className="list-group-item-action dropdown-item pointer"
                                key={i}>{t.name}
                            </li>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default TypeInput
