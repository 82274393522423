import React, { useState, useEffect } from 'react'
import ProductCard from '../Components/ProductCard'
import { allProducts } from '../Actions/Product'
import Category from '../Components/Category'
import Pagination from '../Components/Pagination'

import { useSelector, useDispatch } from 'react-redux'
import { getWishList } from '../Actions/WishList'
import Slider from '../Components/Slider'
import { useTitleHook } from "../Hooks/TitleHook"

function Home() {

    const [products, setProducts] = useState(null)
    const [wishListId, setWishListId] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [hookTitle, setHookTitle] = useTitleHook(process.env.REACT_APP_SITE_TITLE);

    const { auth, wishlist } = useSelector(state => ({...state}))
    const dispatch = useDispatch()

    useEffect(() => {
        !products && loadProduct()
        auth?.token && !wishlist && getWishList(auth?.token).then( r => {
            if(r.data?.success) {
                // dispatch({
                //     type: 'SET_WISHLIST',
                //     payload: [...r.data.data]
                // })
                let temp = r.data.data.map( w => w.productId._id )
                setWishListId(temp)
            }
        })    
    }, [])

    const loadProduct = async () => {
        try {
            let { data } = await allProducts()
            setProducts(data)
            setLoaded(true)
            // console.log(data);
        } catch (error) {
            console.log('Error: connecting to api/products');
        }
    }

    return (
        <>
            <Slider />
            <div className='container mb-4 d-none d-sm-block'>
                <Category />
            </div>
            <div className="">
                {/* Page title + breadcrumb*/}
                {/* <nav className="mb-4" aria-label="breadcrumb">
                        <ol className="breadcrumb flex-lg-nowrap">
                            <li className="breadcrumb-item"><a className="text-nowrap" href="home-grocery-store.html"><i className="ci-home" />Home</a></li>
                            <li className="breadcrumb-item text-nowrap active" aria-current="page">Recent Add</li>
                        </ol>
                    </nav> */}
                {/* Content*/}
                {/* Sorting*/}
                <section className="d-md-flex justify-content-between align-items-center mb-4 pb-2">
                    <h1 className="h3 mb-3 mb-md-0 me-3 fw-bold">Recent Add</h1>
                    <div className="d-flex align-items-center">
                        <div className="d-none d-sm-block py-2 fs-sm text-muted text-nowrap me-2">Sort by:</div>
                        <ul className="nav nav-tabs fs-sm mb-0">
                            <li className="nav-item"><a className="nav-link active" href="#">Popular</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Cheap</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Expensive</a></li>
                        </ul>
                    </div>
                </section>
                {/* Product grid*/}
                <div className="row g-0 mx-n2">
                    {   !loaded ? <div className='w-100 d-flex justify-content-center h-100 p-5' style={{ backgroundImage: `url(${require('../Assets/loading.gif').default})`, backgroundColor: '#f6f9fc', backgroundBlendMode: 'multiply'}}>
                        {/* <img className='spinner-borderx text-primary' stylea={{ width: '3rem', height: '3rem'}} role="status" src={ require('../Assets/loading.gif').default } /> */}
                        </div>
                        : products.map(p => <ProductCard product={p} key={p._id} wishListId={wishListId} setWishListId={setWishListId}/>)
                    }
                </div>
                <Pagination />

                <div className="col-md-12 mt-2 mb-5">
                    <div className="d-flex flex-column h-100 justify-content-center bg-faded-success bg-size-cover bg-position-center border rounded-3" style={{backgroundImage: 'url()'}}>
                        <div className="py-4 my-2 px-4 text-center">
                            <div className="py-1">
                                <h5 className="mb-2 kh-font mt-1">ទាក់ទងផ្សាយពាណិជ្ចកម្ម | Your Ads Banner Here</h5>
                                <p className="fs-sm text-muted">Hurry up to reserve your spot</p>
                                <h3 className='fw-bold mb-0 text-accent'><i className='ci-mobile'/> +855 93 575 933</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="d-md-flex justify-content-between align-items-center mb-4 pb-2">
                    <h1 className="h3 mb-3 mb-md-0 me-3 fw-bold">Most Views</h1>
                    <div className="d-flex align-items-center">
                        <div className="d-none d-sm-block py-2 fs-sm text-muted text-nowrap me-2">Sort by:</div>
                        <ul className="nav nav-tabs fs-sm mb-0">
                            <li className="nav-item"><a className="nav-link active" href="#">Popular</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Cheap</a></li>
                            <li className="nav-item"><a className="nav-link" href="#">Expensive</a></li>
                        </ul>
                    </div>
                </section>
                
                <div className="row g-0 mx-n2">
                    {   !loaded ? <div className='w-100 d-flex justify-content-center h-100 p-5'>
                        {/* <img className='spinner-borderx text-primary' stylea={{ width: '3rem', height: '3rem'}} role="status" src={ require('../Assets/loading.gif').default } /> */}
                        </div>
                        : products.map(p => <ProductCard product={p} key={p._id} />)
                    }
                </div>

                <Pagination />

            </div>
        </>
    )
}

export default Home
