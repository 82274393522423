import { useEffect, useState } from "react";

const useTitleHook = title => {
  const [hookTitle, setHookTitle] = useState(title);

  useEffect(() => {
    document.title = hookTitle;
  }, [hookTitle]);

  return [hookTitle, setHookTitle];
};

export { useTitleHook };