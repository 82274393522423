import React from 'react'

function YouMayLike() {
    return (
        <>
            <section className="pb-5 mb-2 mb-xl-4">
  <h2 className="h3 pb-2 mb-grid-gutter text-center">You may also like</h2>
  <div className="tns-carousel tns-controls-static tns-controls-outside tns-nav-enabled">
    <div className="tns-outer" id="tns1-ow"><div className="tns-controls" aria-label="Carousel Navigation" tabIndex={0}><button type="button" data-controls="prev" tabIndex={-1} aria-controls="tns1"><i className="ci-arrow-left" /></button><button type="button" data-controls="next" tabIndex={-1} aria-controls="tns1"><i className="ci-arrow-right" /></button></div><div className="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">slide <span className="current">10 to 12</span>  of 7</div><div id="tns1-mw" className="tns-ovh"><div className="tns-inner" id="tns1-iw"><div className="tns-carousel-inner  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" data-carousel-options="{&quot;items&quot;: 2, &quot;gutter&quot;: 16, &quot;controls&quot;: true, &quot;responsive&quot;: {&quot;0&quot;:{&quot;items&quot;:1}, &quot;480&quot;:{&quot;items&quot;:2}, &quot;720&quot;:{&quot;items&quot;:3}, &quot;991&quot;:{&quot;items&quot;:2}, &quot;1140&quot;:{&quot;items&quot;:3}, &quot;1300&quot;:{&quot;items&quot;:4}, &quot;1500&quot;:{&quot;items&quot;:5}}}" id="tns1" style={{transitionDuration: '0s', transform: 'translate3d(-36%, 0px, 0px)'}}><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/13.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Fish and Seafood</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Steak Salmon Fillet (1kg)</a></h3>
                  <div className="product-price"><span className="text-accent">$17.<small>99</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/14.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Canned Food and Oil</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Sardine in Tomato Sauce (105g)</a></h3>
                  <div className="product-price"><span className="text-accent">$3.<small>25</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/08.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Dairy and Eggs</a>
                  <h3 className="product-title fs-sm"><a href="grocery-single.html">Mozzarella Cheese (125g)</a></h3>
                  <div className="product-price"><span className="text-accent">$4.<small>30</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/09.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Personal hygiene</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Men’s Shampoo (400ml)</a></h3>
                  <div className="product-price"><span className="text-accent">$5.<small>99</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/10.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Meat and Poultry</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Frozen Oven-ready Poultry</a></h3>
                  <div className="product-price"><span className="text-accent">$12.<small>00</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/11.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Snacks, Sweets and Chips</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Dark Chocolate with Nuts</a></h3>
                  <div className="product-price"><span className="text-accent">$2.<small>50</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/12.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Canned Food and Oil</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Corn Oil Bottle (500ml)</a></h3>
                  <div className="product-price"><span className="text-accent">$3.<small>10</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/13.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Fish and Seafood</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Steak Salmon Fillet (1kg)</a></h3>
                  <div className="product-price"><span className="text-accent">$17.<small>99</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/14.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Canned Food and Oil</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Sardine in Tomato Sauce (105g)</a></h3>
                  <div className="product-price"><span className="text-accent">$3.<small>25</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div>
            {/* Product*/}
            <div className="tns-item tns-slide-active" id="tns1-item0">
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/08.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Dairy and Eggs</a>
                  <h3 className="product-title fs-sm"><a href="grocery-single.html">Mozzarella Cheese (125g)</a></h3>
                  <div className="product-price"><span className="text-accent">$4.<small>30</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div>
            {/* Product*/}
            <div className="tns-item tns-slide-active" id="tns1-item1">
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/09.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Personal hygiene</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Men’s Shampoo (400ml)</a></h3>
                  <div className="product-price"><span className="text-accent">$5.<small>99</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div>
            {/* Product*/}
            <div className="tns-item tns-slide-active" id="tns1-item2">
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/10.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Meat and Poultry</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Frozen Oven-ready Poultry</a></h3>
                  <div className="product-price"><span className="text-accent">$12.<small>00</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div>
            {/* Product*/}
            <div className="tns-item" id="tns1-item3" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/11.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Snacks, Sweets and Chips</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Dark Chocolate with Nuts</a></h3>
                  <div className="product-price"><span className="text-accent">$2.<small>50</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div>
            {/* Product*/}
            <div className="tns-item" id="tns1-item4" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/12.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Canned Food and Oil</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Corn Oil Bottle (500ml)</a></h3>
                  <div className="product-price"><span className="text-accent">$3.<small>10</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div>
            {/* Product*/}
            <div className="tns-item" id="tns1-item5" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/13.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Fish and Seafood</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Steak Salmon Fillet (1kg)</a></h3>
                  <div className="product-price"><span className="text-accent">$17.<small>99</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div>
            {/* Product*/}
            <div className="tns-item" id="tns1-item6" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/14.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Canned Food and Oil</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Sardine in Tomato Sauce (105g)</a></h3>
                  <div className="product-price"><span className="text-accent">$3.<small>25</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div>
            <div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/08.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Dairy and Eggs</a>
                  <h3 className="product-title fs-sm"><a href="grocery-single.html">Mozzarella Cheese (125g)</a></h3>
                  <div className="product-price"><span className="text-accent">$4.<small>30</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/09.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Personal hygiene</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Men’s Shampoo (400ml)</a></h3>
                  <div className="product-price"><span className="text-accent">$5.<small>99</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/10.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Meat and Poultry</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Frozen Oven-ready Poultry</a></h3>
                  <div className="product-price"><span className="text-accent">$12.<small>00</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/11.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Snacks, Sweets and Chips</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Dark Chocolate with Nuts</a></h3>
                  <div className="product-price"><span className="text-accent">$2.<small>50</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/12.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Canned Food and Oil</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Corn Oil Bottle (500ml)</a></h3>
                  <div className="product-price"><span className="text-accent">$3.<small>10</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/13.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Fish and Seafood</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Steak Salmon Fillet (1kg)</a></h3>
                  <div className="product-price"><span className="text-accent">$17.<small>99</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/14.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Canned Food and Oil</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Sardine in Tomato Sauce (105g)</a></h3>
                  <div className="product-price"><span className="text-accent">$3.<small>25</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/08.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Dairy and Eggs</a>
                  <h3 className="product-title fs-sm"><a href="grocery-single.html">Mozzarella Cheese (125g)</a></h3>
                  <div className="product-price"><span className="text-accent">$4.<small>30</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div><div className="tns-item tns-slide-cloned" aria-hidden="true" tabIndex={-1}>
              <div className="card product-card card-static pb-3">
                <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title data-bs-original-title="Add to wishlist" aria-label="Add to wishlist"><i className="ci-heart" /></button><a className="card-img-top d-block overflow-hidden" href="grocery-single.html"><img src="/img/grocery/catalog/09.jpg" alt="Product" /></a>
                <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Personal hygiene</a>
                  <h3 className="product-title fs-sm text-truncate"><a href="grocery-single.html">Men’s Shampoo (400ml)</a></h3>
                  <div className="product-price"><span className="text-accent">$5.<small>99</small></span></div>
                </div>
                <div className="product-floating-btn">
                  <button className="btn btn-primary btn-shadow btn-sm" type="button">+<i className="ci-cart fs-base ms-1" /></button>
                </div>
              </div>
            </div></div></div></div><div className="tns-nav" aria-label="Carousel Pagination"><button type="button" data-nav={0} aria-controls="tns1" style={{}} aria-label="Carousel Page 1 (Current Slide)" className="tns-nav-active" /><button type="button" data-nav={1} tabIndex={-1} aria-controls="tns1" style={{}} aria-label="Carousel Page 2" /><button type="button" data-nav={2} tabIndex={-1} aria-controls="tns1" style={{}} aria-label="Carousel Page 3" /><button type="button" data-nav={3} tabIndex={-1} aria-controls="tns1" style={{display: 'none'}} aria-label="Carousel Page 4" /><button type="button" data-nav={4} tabIndex={-1} aria-controls="tns1" style={{display: 'none'}} aria-label="Carousel Page 5" /><button type="button" data-nav={5} tabIndex={-1} aria-controls="tns1" style={{display: 'none'}} aria-label="Carousel Page 6" /><button type="button" data-nav={6} tabIndex={-1} aria-controls="tns1" style={{display: 'none'}} aria-label="Carousel Page 7" /></div></div>
  </div>
</section>

        </>
    )
}

export default YouMayLike
