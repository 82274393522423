import React from 'react'

function PriceInput({ state, setState }) {
    return (
        <div className="col-sm-4 mb-3">
            <label className="form-label fw-bold" htmlFor="unp-standard-price">Price</label>
            <div className="input-group"><span className="input-group-text"><i className="ci-dollar" /></span>
                <input
                    value={state.pricing.price}
                    onChange={(e) => setState({ ...state, pricing: { ...state.pricing, price: e.target.value } })}
                    className="form-control" type="text" id="unp-standard-price" autoComplete="off"
                />
            </div>
            {/* <div className="form-text">Average marketplace price for this category is $15.</div> */}
        </div>
    )
}

export default PriceInput
