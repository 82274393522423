import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// 1. Import from react-redux and redux
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './Reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

// 4. Create redux store
const store = createStore(rootReducer, composeWithDevTools())

// 5. Provide redux store to the entire app
ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
